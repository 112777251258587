/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import { getField } from 'vuex-map-fields';
import http from '../services/backendApi';
import { ACTIONS, MUTATIONS, GETTERS } from './account.constants';
import { ACTIONS as ROUTE_ACTIONS } from '../views/map/routes/routes.constants';
import { ACTIONS as GAME_ACTIONS } from '../views/game/game.constants';
import router from '../config/router';

const authentication = {
  state: {
    user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
    status: null,
    validToken: false,
    accessToken: localStorage.getItem('token') || '',
  },
  getters: {
    [GETTERS.LOGGED_IN]: state => !!state.accessToken,
    roles: state => state.user.role,
    accessToken: state => state.accessToken,
    getField,
  },
  actions: {
    [ACTIONS.LOGIN]({ dispatch, commit }, { username, password }) {
      const options = {
        headers: {
          authorization: `Basic ${btoa(`${username}:${password}`)}`,
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };
      const body = {
        access_token: process.env.VUE_APP_KEY,
      };
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_URL}/auth/?access_token=${process.env.VUE_APP_KEY}`, body, options).then((response) => {
          if (response.data.token) {
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('user', JSON.stringify(response.data.user));
            commit(MUTATIONS.SET_CREDENTIALS, response.data);
            resolve();
          }
        }).catch(() => {
          reject();
          console.error(`Error loading data from ${process.env.VUE_APP_API_URL}/auth/`);
        });
      });
    },
    [ACTIONS.SET_USER_NAME]({}, data) {
      return http.patch(`${process.env.VUE_APP_API_URL}/users/me`, data);
    },
    [ACTIONS.CHECK_USER]({ dispatch, commit, state }) {
      if (state.userId && state.accessToken) {
        http.get(`${process.env.VUE_APP_API_URL}/users/me`)
          .then(
            (response) => {
              commit(MUTATIONS.SET_INITIAL_USER, response.data);
            },
            () => {
              dispatch(ACTIONS.LOGOUT);
            },
          );
      } else {
        dispatch(ACTIONS.LOGOUT);
      }
    },
    [ACTIONS.GET_ME]({ commit, dispatch }) {
      http.get(`${process.env.VUE_APP_API_URL}/users/me`)
        .then(
          (response) => {
            commit(MUTATIONS.SET_USER, response.data);
            dispatch(GAME_ACTIONS.GET_GAME, {root: true});
            dispatch(ROUTE_ACTIONS.SET_LAST_COORDINATES, response.data, {root: true});
            dispatch(ROUTE_ACTIONS.SET_START_HOUR, response.data, {root: true});
            dispatch(ROUTE_ACTIONS.SET_END_HOUR, response.data, {root: true});
          },
        );
    },
    [ACTIONS.CHECK_TOKEN]({ commit }, token) {
      commit(MUTATIONS.LOGOUT);
      localStorage.setItem('token', '');
      localStorage.setItem('userId', '');
      localStorage.setItem('user', null);
      http.get(`${process.env.VUE_APP_API_URL}/password-resets/${token}`)
        .then(
          (response) => {
            commit(MUTATIONS.SET_TOKEN_VALID, response.data);
          },
        );
    },
    [ACTIONS.LOGOUT]({ commit }) {
      // remove user from local storage to log user out
      commit(MUTATIONS.LOGOUT);
      localStorage.setItem('token', '');
      localStorage.setItem('userId', '');
      localStorage.setItem('user', '');
      router.push('/login');
    },
    [ACTIONS.ADD_ROUTE_TO_USER]({ commit }, value) {
      commit(MUTATIONS.ADD_ROUTE_TO_USER, value);
    },
  },
  mutations: {
    [MUTATIONS.SET_CREDENTIALS](state, response) {
      state.accessToken = response.token;
      state.user = response.user;
    },
    [MUTATIONS.SET_USER](state, user) {
      state.user = user;
    },
    [MUTATIONS.ADD_ROUTE_TO_USER](state, route) {
      state.user.request.push(route);
    },
    [MUTATIONS.LOGOUT](state) {
      state.accessToken = null;
      state.user = null;
      state.userId = null;
    },
  },
};

export default authentication;
