<template>
  <div>
    <div class="code" ref="code"><p><span>ERROR CODE</span>: "<i>HTTP 403 Forbidden</i>"</p>
      <p>> <span>ERROR DESCRIPTION</span>: "<i>Toegang geweigerd. U heeft geen toestemming om tijdens dit onderzoek deze pagina te raadplegen.</i>"</p>
      <p>> <i></i></p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'access-denied',
    data() {
      return {
        se: null
      };
    },
    destroyed() {
      clearInterval(this.se);
    },
    mounted() {
      var str = this.$refs.code.innerHTML.toString();
      var i = 0;
      this.$refs.code.innerHTML = '';
      let that = this;
      setTimeout(function () {
        that.se = setInterval(function () {
          i++;
          that.$refs.code.innerHTML = str.slice(0, i) + '|';
          if (i == str.length) {
            clearInterval(that.se);
            that.$refs.code.innerHTML = str;
          }
        }, 10);
      }, 0);
    }
  };
</script>

<style scoped lang="scss">
  @import url("https://fonts.googleapis.com/css?family=Share+Tech+Mono|Montserrat:700");

  .code {
    padding: 30px 30px 10px;
    z-index: 3;

    h1 {
      font-size: 45vw;
      text-align: center;
      position: fixed;
      width: 100vw;
      z-index: 1;
      color: #ffffff26;
      text-shadow: 0 0 50px rgba(0, 0, 0, 0.07);
      top: 50%;
      transform: translateY(-50%);
      font-family: "Montserrat", monospace;
    }

    p {
      font-family: "Share Tech Mono", monospace;
      color: #f5f5f5;
      margin: 0 0 20px;
      font-size: 17px;
      line-height: 1.2;
    }

    span {
      color: #b32200;
    }

    i {
      color: #eaeaf1;
    }

    div a {
      text-decoration: none;
    }

    b {
      color: #81a2be;
    }


    @keyframes slide {
      from {
        right: -100px;
        transform: rotate(360deg);
        opacity: 0;
      }
      to {
        right: 15px;
        transform: rotate(0deg);
        opacity: 1;
      }
    }
  }


</style>
