<template>
  <MglMap ref="map" width="100%" id="location-tracking" v-if="mapStyle" :accessToken="accessToken" :mapStyle="mapStyle"
          :dragRotate="false" :pitch="0" :min-zoom="8">
    <MglNavigationControl position="bottom-right"/>
    <map-marker v-for="marker in markers" :marker="marker"></map-marker>
    <map-marker v-if="finalCoordinateMarker" :marker="finalCoordinateMarker" :color="true"></map-marker>
    <map-navigation></map-navigation>
  </MglMap>
</template>

<script>
  import { mapState } from 'vuex';
  import Mapbox from 'mapbox-gl';
  import { MglMap, MglNavigationControl } from 'vue-mapbox';
  import MapMarker from './marker';
  import MapNavigation from './navigation';

  export default {
    name: 'investigation-map',
    components: {
      MglNavigationControl,
      MapNavigation,
      MapMarker,
      MglMap,
    },
    data() {
      return {
        field: 'phone',
        counter: 0,
        markers: [],
        finalCoordinateMarker: null,
        accessToken: process.env.VUE_APP_MAP_BOX_KEY,
        mapStyle: 'mapbox://styles/siebevh/ck9i7cpe70l8o1is3k6sxn8gd', // your map style
      };
    },
    computed: {
      ...mapState({
        person: state => state.persons.person,
        coordinates: state => state.route.marker,
        noMatch: state => state.persons.noMatch
      }),
      center() {
        return this.person ? this.person.location : this.coordinates;
      }
    },
    watch: {
      noMatch() {
        this.startSearching();
      },
      person() {
        this.startSearching();
      }
    },
    created() {
      this.mapbox = Mapbox;
    },
    methods: {
      startSearching() {
        let coordinates = this.coordinates;
        if (this.person) {
          console.log(this.person);
          coordinates = [parseFloat(this.person.location[0]), parseFloat(this.person.location[1])];
        }
        this.markers.push(this.getLocation(coordinates[0], coordinates[1], 5000));
        this.$refs.map.map.flyTo({
          center: coordinates,
          essential: true
        });
        this.atMarker(coordinates);
      },
      atMarker(coordinates) {
        if (this.counter < 2) {
          setTimeout(() => {
            const newCoordinates = this.getLocation(coordinates[0], coordinates[1], 5000);
            this.counter += 1;
            this.markers.push(newCoordinates);
            this.atMarker(coordinates);
          }, 5000);
        } else {
          setTimeout(() => {
            if (this.person) {
              this.finalCoordinateMarker = coordinates;
            }
          }, 5000);
        }
      },
      getLocation(x0, y0, radius) {
        // Convert radius from meters to degrees
        let radiusInDegrees = radius / 111000;
        let u = Math.random();
        let v = Math.random();
        let w = radiusInDegrees * Math.sqrt(u);
        let t = 2 * Math.PI * v;
        let x = w * Math.cos(t);
        let y = w * Math.sin(t);
        // Adjust the x-coordinate for the shrinking of the east-west distances
        let new_x = x / Math.cos(y0 * (Math.PI / 180));
        let foundLongitude = new_x + x0;
        let foundLatitude = y + y0;
        return [foundLongitude, foundLatitude];
      }
    }
  };
</script>

<style lang="scss">
  @import "@/styles/colors";

  #map {
    height: calc(100vh - 170px) !important;
  }

  .mapboxgl-map {
    width: 100% !important;
    height: calc(100vh - 170px) !important;
    border: $secondary 1px solid;
  }
</style>
