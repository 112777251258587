<template>
  <div class="person_card" id="bright">
    <div class="info_section">
      <div class="header">
        <h1>{{title}}</h1>
        <h4>{{subTitle}}</h4>
      </div>
      <div class="desc">
        <template v-for="(value, name) in data">
          <h4 class="type">{{name}}</h4>
          <p>{{value}}</p>
        </template>

      </div>
    </div>
    <div class="blur_back bright_back" :style="{'background-image': `url(${img})`}"></div>
  </div>
</template>

<script>
  export default {
    name: 'person-card',
    props: {
      data: {
        type: Object
      },
      title: {
        type: String,
      },
      subTitle: {
        type: String,
      },
      img: {
        type: String,
      }
    }
  };
</script>

<style lang="scss" scoped>
  .person_card {
    position: relative;
    display: block;
    width: 800px;
    height: 350px;
    margin: 100px auto;
    overflow: hidden;
    border-radius: 10px;
    transition: all 0.4s;

    &:hover {
      transform: scale(1.02);
      transition: all 0.4s;
    }

    .info_section {
      position: relative;
      width: 100%;
      height: 100%;
      background-blend-mode: multiply;
      z-index: 2;
      border-radius: 10px;

      .header {
        position: relative;
        padding: 15px;
        height: 40%;

        h1 {
          color: #fff;
          font-weight: 400;
        }

        h4 {
          color: #9ac7fa;
          font-weight: 400;
        }

        .type {
          display: inline-block;
          color: #cee4fd;
          margin-left: 10px;
        }
      }

      .desc {
        padding: 15px;
        height: 50%;

        h4 {
          color: #fff;
          text-transform: uppercase;
          font-weight: 400;
        }

        .text {
          color: #cfd6e1;
        }
      }
    }

    .blur_back {
      position: absolute;
      top: 0;
      z-index: 1;
      height: 100%;
      right: 0;
      background-size: cover;
      border-radius: 11px;
    }
  }

  @media screen and (min-width: 768px) {
    .header {
      width: 60%;
    }

    .desc {
      width: 50%;
    }

    .info_section {
      background: linear-gradient(to right, #0d0d1a 50%, transparent 100%);
    }

    .blur_back {
      width: 80%;
      background-position: -100% 10% !important;
    }
  }

  @media screen and (max-width: 768px) {
    .person_card {
      width: 95%;
      margin: 70px auto;
      min-height: 350px;
      height: auto;
    }

    .blur_back {
      width: 100%;
      background-position: 50% 50% !important;
    }

    .header {
      width: 100%;
      margin-top: 85px;
    }

    .desc {
      width: 100%;
    }

    .info_section {
      background: linear-gradient(to top, rgb(20, 20, 19) 50%, transparent 100%);
      display: inline-grid;
    }
  }


  #bright {
    box-shadow: 0px 0px 150px -45px rgba(255, 51, 0, 0.5);

    &:hover {
      box-shadow: 0px 0px 120px -55px rgba(255, 51, 0, 0.5);
    }
  }

  .bright_back {
    background: url("../../assets/persons/criminal1.jpg");
  }

</style>
