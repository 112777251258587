<template>
    <div class="wrapper" id="wrapper">
        <div class="modal-top">
            <v-btn to="/endPage" class="closeBtn mr-12" fab small top right color="grey">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </div>
        <div class="content-body" id='test'>
            <svg-border :time="time"></svg-border>
            <video id=video ref="video" class="camera-stream" />
        </div>

        <v-btn v-if="!imageTaken" class="cameraButton" fab large top right color="#00FFCE" @click="takePicture">
            <v-icon color="black">mdi-camera</v-icon>
        </v-btn>
        <div class="buttonGroup" v-else>
            <v-btn class="ma-2" fab large top right color="#00FFCE" @click="retake">
                <v-icon color="black">mdi-camera-retake</v-icon>
            </v-btn>
            <v-btn class="ma-2" fab large top right color="#00FFCE" @click="save">
                <v-icon color="black">mdi-download</v-icon>
            </v-btn>
        </div>

        <canvas id="canvas" width="1200px" height="800px"></canvas>
        <img/>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import svgBorder from './svg-border.vue';
import http from '../../../services/backendApi';
import WebCam from 'vue-web-cam';

export default {
    name: 'endPage',
    components: {
        svgBorder,
        WebCam
    },
    data() {
        return {
            stream: null,
            imageTaken: false,
        }
    },
    mounted() {
        // this.takePicture();
    },
    computed: {
        ...mapState({
            startDate: state => state.route.startHour,
            endDate: state => state.route.endHour,
            game: state => state.game.game,
            user: state => state.authentication.user._id,

        }),
        time() {
            let duration = new Date(this.endDate).getTime() - new Date(this.startDate).getTime();
            var seconds = Math.floor((duration / 1000) % 60),
                minutes = Math.floor((duration / (1000 * 60)) % 60),
                hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

            hours = '0' + hours;
            minutes = (minutes < 10) ? '0' + minutes : minutes;
            seconds = (seconds < 10) ? '0' + seconds : seconds;

            return hours + ':' + minutes + ':' + seconds;
        },
        title() {
            return this.$tc('endPage.facebook-title', { game: this.game.name, time: this.time })
        }
    },
    mounted() {
        navigator.mediaDevices.getUserMedia({ video: { width: 1280, height: 720 } })
            .then(mediaStream => {
                this.$refs.video.srcObject = mediaStream
                this.$refs.video.play()
            })
            .catch(error => console.error('getUserMedia() error:', error))
    },
    methods: {
        save() {
            const canvas = document.getElementById('canvas'),
                img = document.querySelector('img'),
                context = canvas.getContext('2d'),
                video = document.getElementById('video');

            context.drawImage(video, 160, 100, 960, 540);

            img.onload = () => {
                // draw the image onto the canvas
                context.drawImage(img, 0, 0, 1200, 800);
                const dataURL = canvas.toDataURL(),
                    a = document.createElement("a"); //Create <a>
                a.href = dataURL; //Image Base64 Goes here
                a.download = "Eskapo.png"; //File name Here
                a.click(); //Downloaded file
            }
            img.src = this.getFrame();

        },
        takePicture() {
            this.$refs.video.pause()
            this.imageTaken = true;
        },
        retake() {
            this.$refs.video.play()
            this.imageTaken = false;
        },
        getFrame() {
            var svg = document.getElementById('image-border');
            // get svg data
            var xml = new XMLSerializer().serializeToString(svg);
            // make it base64
            var svg64 = btoa(xml);
            var b64Start = 'data:image/svg+xml;base64,';

            console.log(b64Start + svg64)
            // prepend a "header"
            return b64Start + svg64;
        }
    }
};
</script>

<style lang="scss" scoped>
.wrapper {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-size: cover;
    background-color: #020B21;
    z-index: 1000;
    display: flex;
    align-items: center;
}

.content-body {
    position: fixed;
    top: 0;
    width: 100vw;
    max-height: 100%;
    display: flex;
    justify-content: center;
    flex: 1;
}

.closeBtn {
    position: absolute;
    top: 10px;
    right: 0;
    z-index: 2000;
}

.cameraButton {
    position: absolute;
    z-index: 2000;
    bottom: 20px;
    left: 50%;
}

.buttonGroup {
    position: absolute;
    width: 100vw;
    bottom: 20px;
    display: flex;
    justify-content: center;
}

.margin-top {
    margin-top: 20%;
}

.hiden {
    display: hidden;
}

.camera-stream {
    padding: 5%;
    height: 56.25vw;
    max-height: 100vh;
    width: 100vw;
    max-width: 177.78vh;
    margin: auto;
    z-index: -1;
    position: absolute;
    top: 0;
    bottom:0;
    left: 0;
    right: 0;
}

svg {
    height: 100vh;
}

#canvas,
img {
    visibility: hidden;
}
</style>
