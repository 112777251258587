<template>
  <div>
    <div class="code" ref="code" v-if="game">
      <p class="secondary--text text-type-writer">> <span>{{$t('intro.welcome')}}</span></p>
      <p class="secondary--text hidden text-type-writer">> <span>{{$t('intro.intro')}}</span></p>
      <p class="secondary--text hidden text-type-writer">> <span>{{$t('intro.guide')}}</span></p>
      <p class="secondary--text hidden text-type-writer">> <span>{{$t('intro.operation-detail')}}</span><i> "{{game.name}}"</i></p>
      <p class="secondary--text hidden text-type-writer">> <span>{{$t('intro.time')}}</span><i> {{game.time}}</i></p>
      <p class="secondary--text hidden text-type-writer">> <span>{{$t('intro.first-time')}}</span></p>
      <div class="ml-10" >
        <v-btn class="mr-5 buttons" outlined color="secondary" @click="firstTime()">{{$t('intro.tour')}}</v-btn>
        <v-btn class="mr-5 buttons" outlined color="secondary" @click="start()">{{$t('intro.start')}}</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import { ACTIONS as GAME_ACTIONS } from '../game/game.constants';
  import { ACTIONS } from '../map/routes/routes.constants';


  export default {
    name: 'access-denied',
    data() {
      return {
        showButtons: false,
        firstTimeBoolean: false,
        body:'',
      };
    },
    computed: {
      ...mapState({
        game: state => state.game.game,
        user: state => state.authentication.user,
      })
    },
    mounted() {
      this.getGame();
    },
    methods: {
      ...mapActions({
        getGame: GAME_ACTIONS.GET_GAME,
        startGame: ACTIONS.START_GAME,
      }),
      firstTime() {
        this.$router.push({ path: 'map' });
        this.$tours['intro'].start();
      },
      start() {
        this.$router.push({ path: 'map' });
        this.startGame(this.game);
      }
    },
  };
</script>

<style scoped lang="scss">
  @import url("https://fonts.googleapis.com/css?family=Share+Tech+Mono|Montserrat:700");

  .code {
    padding: 30px 30px 10px;
    z-index: 3;

    h1 {
      font-size: 45vw;
      text-align: center;
      position: fixed;
      width: 100vw;
      z-index: 1;
      color: #ffffff26;
      text-shadow: 0 0 50px rgba(0, 0, 0, 0.07);
      top: 50%;
      transform: translateY(-50%);
      font-family: "Montserrat", monospace;
    }

    p {
      font-family: "Share Tech Mono", monospace;
      color: #f5f5f5;
      margin: 0 0 20px;
      font-size: 17px;
      line-height: 1.2;
    }

    i {
      color: white!important;
    }

    div a {
      text-decoration: none;
    }

    b {
      color: #81a2be;
    }

    .text-type-writer {
      border-right: .15em solid #d7d7de;
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      -webkit-animation: type 2s steps(40, end);
      animation: type 2s steps(40, end);
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      opacity: 0;
    }

    .text-type-writer:nth-of-type(1) {
      width: 8em;
      -webkit-animation-delay: 0s;
      animation-delay: 0s;
    }

    .text-type-writer:nth-of-type(2) {
      width: 35em;
      -webkit-animation-delay: 2s;
      animation-delay: 2s;
    }

    .text-type-writer:nth-of-type(3) {
      width: 36em;
      -webkit-animation-delay: 4s;
      animation-delay: 4s;
    }

    .text-type-writer:nth-of-type(4) {
      width: 30em;
      -webkit-animation-delay: 6s;
      animation-delay: 6s;
    }

    .text-type-writer:nth-of-type(5) {
      width: 30em;
      -webkit-animation-delay: 8s;
      animation-delay: 8s;
    }

    .text-type-writer:nth-of-type(6) {
      width: 80em;
      -webkit-animation-delay: 10s;
      animation-delay: 10s;
    }


    .buttons {
      visibility: hidden;
      animation: showButtons 1s 12s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
    }

    @keyframes showButtons {
      0% {
        visibility: hidden;
      }
      1% {
        visibility: visible;
      }
      100% {
        visibility: visible;
      }
    }

    @-webkit-keyframes showButtons {
      0% {
        visibility: hidden;
      }
      1% {
        visibility: visible;
      }
      100% {
        visibility: visible;
      }
    }


    @keyframes type {
      0% {
        width: 0;
      }
      1% {
        opacity: 1;
      }
      99.9% {
        border-right: .15em solid orange;
      }
      100% {
        opacity: 1;
        border: none;
      }
    }

    @-webkit-keyframes type {
      0% {
        width: 0;
      }
      1% {
        opacity: 1;
      }
      99.9% {
        border-right: .15em solid orange;
      }
      100% {
        opacity: 1;
        border: none;
      }
    }

    @keyframes blink {
      50% {
        border-color: transparent;
      }
    }
    @-webkit-keyframes blink {
      50% {
        border-color: tranparent;
      }
    }
  }




</style>
