import api from '../../../services/backendApi';
import { ACTIONS, MUTATIONS } from './routes.constants';
import { ACTIONS as USER_ACTIONS } from '../../../auth/account.constants';
import Vue from 'vue';
import router from '../../../config/router'

let timeout = null;

const route = {
  state: {
    hasShownEndScreen: false,
    dialog: null,
    startHour: null,
    endHour: null,
    lastLocation: 0,
    collected: 0,
    route: {
      type: 'geojson',
      data:
        {
          id: 'route',
          type: 'Feature',
          properties: {},
          geometry: {
            coordinates: [],
            type: 'LineString'
          },
        }
    },
    marker: [4.5488247449436585,50.994565520171506]
  },
  actions: {
    [ACTIONS.START_GAME]({ commit }, game) {
      api.post(`${process.env.VUE_APP_API_URL}/users/start`)
        .then(
          (response) => {
            commit(MUTATIONS.SET_MARKER, game.startLocation);
            commit(MUTATIONS.SET_START_HOUR, response.data.startHour);
            commit(MUTATIONS.SET_COLLECTED, response.data.collected);
            commit(MUTATIONS.SET_END_HOUR, null);
          },
        );
    },
    [ACTIONS.END_GAME]({ commit }, game) {
      api.post(`${process.env.VUE_APP_API_URL}/users/end`)
        .then(
          (response) => {
            commit(MUTATIONS.SET_END_HOUR, new Date());
            router.push('/endPage');
          },
        );
    },
    [ACTIONS.SET_START_HOUR]({ commit }, date) {
      commit(MUTATIONS.SET_START_HOUR, date.startHour);
    },
    [ACTIONS.SET_END_HOUR]({ commit }, date) {
      commit(MUTATIONS.SET_END_HOUR, date.endHour);
    },
    async [ACTIONS.GET_ROUTE]({ dispatch, commit }, route) {
      try {
        const response = await api.post(`${process.env.VUE_APP_API_URL}/route`, route);
        dispatch(ACTIONS.CALCULATE_ROUTE, response.data);
        dispatch(USER_ACTIONS.ADD_ROUTE_TO_USER, response.data, { root: true });
      } catch (e) {
        console.log(e);
      }
    },
    [ACTIONS.CALCULATE_ROUTE]({ dispatch, commit }, data) {
      try {
        if (timeout) clearTimeout(timeout);
        const { start, duration, _id, skip, ...route } = data;
        const lineDistance = turf.lineDistance(route.data);
        let path = [];
        for (var i = 0; i < lineDistance; i += lineDistance / (1 * duration)) {
          var segment = turf.along(route.data, i);
          path.push(segment.geometry.coordinates);
        }
        if (skip) {
          path.splice(0, skip);
        }
        route.data.geometry.coordinates = path;
        commit(MUTATIONS.SET_ROUTE, route);
        dispatch(ACTIONS.NAVIGATE_ROUTE);
      } catch (e) {
        console.log(e);
      }
    },
    async [ACTIONS.SET_LAST_COORDINATES]({ dispatch, commit }, user) {
      if (user.request.length > 0) {
        const lastRequest = JSON.parse(JSON.stringify(user.request[user.request.length - 1]));
        const { start, end, duration, _id, skip, ...route } = lastRequest;
        if (new Date() > new Date(end)) {
          const lastCoordinates = lastRequest.lastCoordinates;
          commit(MUTATIONS.SET_MARKER, lastCoordinates);
          commit(MUTATIONS.SET_COLLECTED, user.collected);
        } else {
          const endDate = new Date(end).getTime();
          const now = new Date().getTime();
          lastRequest.skip = duration - Math.floor((endDate - now) / 100);
          dispatch(ACTIONS.CALCULATE_ROUTE, lastRequest);
        }
      }
    },
    async [ACTIONS.NAVIGATE_ROUTE]({ dispatch, commit, state }) {
      try {
        if (state.route.data.geometry.coordinates.length > 1) {
          commit(MUTATIONS.SHIFT_POINT);
          timeout = setTimeout(() => {
            dispatch(ACTIONS.NAVIGATE_ROUTE);
          }, 100);
        } else {
          dispatch(ACTIONS.CHECK_LOCATION, state.route.lastCoordinates);
        }
      } catch (e) {

      }
    },
    async [ACTIONS.CHECK_LOCATION]({ dispatch, commit, state }, marker) {
      try {
        api.post(`${process.env.VUE_APP_API_URL}/route/checkLocation`, {marker})
          .then(
            (response) => {
              if (response.status === 202) {
                commit(MUTATIONS.SET_END_HOUR, new Date());
              }
              dispatch(ACTIONS.SET_DIALOG, response.data);
              if (response.data.lastLocationNumber) {
                 dispatch(ACTIONS.SET_LAST_LOCATION, response.data.lastLocationNumber)
              }
              if (response.data.collected) {
                dispatch(ACTIONS.SET_COLLECTED, response.data.collected)
             }
            },
          );
      } catch (e) {

      }
    },
    [ACTIONS.SET_DIALOG]({ dispatch, commit, state }, dialog) {
      commit(MUTATIONS.SET_DIALOG, dialog);
    },
    [ACTIONS.SET_MARKER]({ commit }, marker) {
      commit(MUTATIONS.SET_MARKER, marker);
    },
    [ACTIONS.SET_LAST_LOCATION]({ commit }, lastLocation) {
      commit(MUTATIONS.SET_LAST_LOCATION, lastLocation);
    },
    [ACTIONS.SET_COLLECTED]({ commit }, collected) {
      commit(MUTATIONS.SET_COLLECTED, collected);
    },
  },
  mutations: {
    [MUTATIONS.SET_START_HOUR](state, response) {
      Vue.set(state, 'startHour', response);
    },
    [MUTATIONS.SET_END_HOUR](state, response) {
      Vue.set(state, 'endHour', response);
    },
    [MUTATIONS.SET_ROUTE](state, response) {
      Vue.set(state, 'route', response);
    },
    [MUTATIONS.SET_MARKER](state, response) {
      state.marker = response;
    },
    [MUTATIONS.SHIFT_POINT](state) {
      state.marker = state.route.data.geometry.coordinates.shift();
    },
    [MUTATIONS.SET_LAST_LOCATION](state, lastLocation) {
      state.lastLocation = lastLocation;
    },
    [MUTATIONS.SET_COLLECTED](state, collected) {
      state.collected = collected;
    },
    [MUTATIONS.SET_DIALOG](state, response) {
      Vue.set(state, 'dialog', response);
    },
  },
};

export default route;
