import Vue from 'vue';
import VueI18n from 'vue-i18n';
import App from './app.vue';
import router from './config/router';
import store from './store';
import vuetify from './plugins/vuetify';
import languageFile from './locales/languages';
import VueTour from 'vue-tour';
import VueSocialSharing from 'vue-social-sharing'

require('vue-tour/dist/vue-tour.css');

Vue.config.productionTip = false;
Vue.use(VueI18n);
Vue.use(VueTour);
Vue.use(VueSocialSharing);

const i18n = new VueI18n({
  locale:  'nl-BE',
  fallbackLocale: 'nl-BE',
  messages: languageFile,
  sync: true
});

new Vue({
	router,
	store,
  i18n,
	vuetify,
	render: h => h(App)
}).$mount('#app');
