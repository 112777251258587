<template>
  <v-dialog
    v-if="dialogContent"
    :value="dialogContent"
    persistent
    origin="top center"
    max-height="90vh"
    max-width="700px"
  >
    <v-card class="text-md-center justify-center" color="primary">
      <v-toolbar dark class="primary " height="100px">
        <h1 class="secondary--text">
          {{ dialogContent.title }}
        </h1>
        <v-spacer></v-spacer>
        <v-btn  icon  @click.native="pushSuccess">
          <v-icon color="secondary">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-img max-height="60vh" contain v-if="logo" :src="logo" class="mb-2"></v-img>
      <v-card-text class="code">
        <p class="secondary--text" v-html="dialogContent.body"></p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import { ACTIONS } from '../../views/map/routes/routes.constants';

  export default {
    name: 'locationDialog',
    computed: {
      ...mapState({
        dialogContent: state => state.route.dialog,
        endHour: state => state.route.endHour,
      }),
      logo() {
        return this.dialogContent.img ? require(`@/assets/location/${this.dialogContent.img}`) : null
      }
    },
    methods: {
      ...mapActions({
        deleteDialog: ACTIONS.SET_DIALOG
      }),
      pushSuccess() {
        if (this.dialogContent.final) {
          this.$router.push('/endPage')
        }
        this.deleteDialog(null);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .code {
    margin-top: 20px;
    p {
      font-family: "Share Tech Mono", monospace;
      color: #f5f5f5;
      margin: 0 0 20px;
      font-size: 17px;
      line-height: 1.2;
    }
  }
</style>
