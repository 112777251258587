<template>
  <div>
    <simple-svg :src="logo.src"></simple-svg>
  </div>
</template>

<script>
  import {SimpleSVG} from 'vue-simple-svg'
	export default {
		name: 'scanner',
    components: {
		  'simple-svg': SimpleSVG
    },
    props: {
		  logo: {
		    type: Object
      }
    }
	};
</script>

<style lang="scss">
  $speed: 2.5s;

  @keyframes scan {
    0% { transform: translate(55px, 24px); }
    40% { transform: translate(55px, 220px); }
    60% { transform: translate(55px, 24px); }
    to { transform: translate(55px, 24px); }
  }

  $card-width: 285px;

  @keyframes showreel {
    0% { transform: translateX(0); }
    10% { transform: translateX(0); }
    20% { transform: translateX(-#{$card-width}); }
    30% { transform: translateX(-#{$card-width}); }
    40% { transform: translateX(-#{$card-width * 2}); }
    50% { transform: translateX(-#{$card-width * 2}); }
    60% { transform: translateX(-#{$card-width * 3}); }
    70% { transform: translateX(-#{$card-width * 3}); }
    80% { transform: translateX(-#{$card-width * 4}); }
    90% { transform: translateX(-#{$card-width * 4}); }
  }

  #Scanner {
    animation: scan $speed cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite;
  }

  #Cards {
    animation: showreel #{$speed * 5} cubic-bezier(0.6, 1.24, 0.76, 1.07) infinite;
  }

</style>
