import axios from 'axios';

const api = axios
	.create({
		baseURL: process.env.VUE_APP_API_URL,
		handlerEnabled: false,
    crossorigin:true
	});


const TOKENS_STORAGE_KEY = 'token';

api.interceptors.request.use((config) => {
	if (localStorage.getItem(TOKENS_STORAGE_KEY) !== null) {
		const token = localStorage.getItem(TOKENS_STORAGE_KEY);
		config.headers.Authorization = `Bearer ${token}`;
	}

	return config;
});

export default api;
