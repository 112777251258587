import api from '../../services/backendApi';
import { ACTIONS, MUTATIONS } from './persons.constants';
import Vue from 'vue';

let timeout = null;

const route = {
  state: {
    loading: false,
    person: null,
    noMatch: false,
  },
  actions: {
    async [ACTIONS.GET_PERSON]({ dispatch, commit }, filter) {
      try {
        commit(MUTATIONS.SET_LOADING, true);
        const response = await api.get(`${process.env.VUE_APP_API_URL}/persons?search=${filter.search}&field=${filter.field}`);
        timeout = setTimeout(() => {
          commit(MUTATIONS.SET_PERSON, response.data);
          commit(MUTATIONS.SET_LOADING, false);
        }, Math.floor(Math.random() * 2000) + 6000);
        return response.data;
      } catch (e) {
        timeout  = setTimeout(() => {
          commit(MUTATIONS.SET_LOADING, false);
          commit(MUTATIONS.SET_NO_MATCH, true);
        }, Math.floor(Math.random() * 2000) + 6000);
        return null;
      }
    },
    async [ACTIONS.RESET_PERSON]({ dispatch, commit }) {
      try {
        commit(MUTATIONS.RESET_PERSON, true);
      } catch (e) {
        console.log(e);
      }
    },
  },
  mutations: {
    [MUTATIONS.SET_PERSON](state, response) {
      Vue.set(state, 'person', response);
    },
    [MUTATIONS.SET_NO_MATCH](state, response) {
      Vue.set(state, 'noMatch', response);
    },
    [MUTATIONS.SET_LOADING](state, response) {
      state.loading = response;
    },
    [MUTATIONS.RESET_PERSON](state, response) {
      clearTimeout(timeout);
      Vue.set(state, 'noMatch', false);
      Vue.set(state, 'loading', false);
      Vue.set(state, 'person', null);
    },
  },
};

export default route;
