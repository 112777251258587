import Vue from 'vue';
import { ACTIONS, GETTERS } from '../auth/account.constants';
import VueRouter from 'vue-router';
import map from '../views/map/map';
import mapTracking from '../views/location-tracking/map';
import atm from '../views/atm/atm';
import store from '../store/index';
import postPackage from '../views/post-package/post';
import accessDenied from '../views/accessDenied/access-denied';
import intro from '../views/introduction/introduction';
import licensePlate from '../views/licensePlate/license-plate';
import hints from '../views/hints/hints';
import endPage from '../views/endPage/endPage';
import drawPage from '../views/draw-screen/draw-screen';
import photobooth from '../views/endPage/photobooth/photobooth';

Vue.use(VueRouter);

const routes = [
	{
		path: '/login',
		name: 'login',
		component: () => import('../auth/login.vue')
	},
  {
    path: '/draw-page',
    name: 'draw-page',
    component: drawPage,
  },
	{
		path: '/',
		redirect: '/map',
	},
  {
    path: '/intro',
    name: 'intro',
    component: intro,
    meta: {
      authentication: true,
    },
  },
  {
    path: '/hints',
    name: 'hints',
    component: hints,
    meta: {
      authentication: true,
    },
  },
	{
		path: '/map',
		name: 'map',
		component: map,
		meta: {
			authentication: true,
		},
	},
  {
    path: '/atm',
    name: 'atm',
    component: atm,
    meta: {
      authentication: true,
    },
  },
  {
    path: '/package',
    name: 'package',
    component: postPackage,
    meta: {
      authentication: true,
    },
  },
  {
    path: '/licence-plate',
    name: 'license',
    component: licensePlate,
    meta: {
      authentication: true,
    },
  },
  {
    path: '/phone',
    name: 'phone',
    component: mapTracking,
    meta: {
      authentication: true,
    },
  },
  {
    path: '/accessDenied',
    name: 'accessDenied',
    component: accessDenied,
    meta: {
      authentication: false,
    },
  },
  {
    path: '/endPage',
    name: 'endPage',
    component: endPage,
    meta: {
      authentication: false,
    },
  },
  {
    path: '/photobooth',
    name: 'photobooth',
    component: photobooth,
    meta: {
      authentication: false,
    },
  },
	{
		path: '/*',
		redirect: '/accessDenied',
	},
];

const router = new VueRouter({
	mode: 'history',
	routes
});

router.beforeEach(async (to, from, next) => {
	// Check for privileges and auth requirements
	const authRequired = to.meta.authentication;
	const adminPrivsRequired = to.meta.adminAuthorization;

	const isLoggedIn = await store.getters[[GETTERS.LOGGED_IN]];
	const isAdmin = await store.getters['authentication/roles'] === 'admin';

	// Check if auth required and user is logged in or admin rights are needed and user has no admin rights
	if ((authRequired && !isLoggedIn) || (adminPrivsRequired && !isAdmin)) {
		store.dispatch([ACTIONS.LOGOUT]);
		return router.push('/login');
	}

	// Allow access to next page
	return next();
});

export default router;
