<template>
  <v-layout>

    <v-container fluid v-if="!noMatch && !loading && !person ">
      <h1 class="text-md-center licenseplate-title">{{$t('licenseplate.title')}}</h1>
      <div class="container">
        <input @keydown.enter="getPerson" type="text" v-model="licensePlate" name="licenseplate" class="licenseplate" maxlength="10"
               placeholder="1-ABC-124"/>
      </div>
      <v-layout justify-center>
        <v-btn large class="searchButton primary--text" color="secondary" :disabled="!licensePlate" @click="getPerson" dark>{{$t('licenseplate.search')}}
        </v-btn>
      </v-layout>
    </v-container>
    <v-container fluid v-if="loading">
      <h1 class="text-md-center licenseplate-title">{{$t('licenseplate.searching')}}</h1>
      <div class="container">
        <scanner :logo="scanImage"></scanner>
      </div>
    </v-container>
    <v-container fluid v-if="person">
      <h1 class="text-md-center licenseplate-title">{{$t('licenseplate.match')}}</h1>
      <div class="container">

        <person-card :data="personData.data" :title="personData.title" :sub-title="personData.subTitle"
                     :img="personData.img"></person-card>
        <v-layout justify-center>
          <v-btn large class="searchButton primary--text" color="secondary" @click="resetInput">{{$t('licenseplate.back')}}</v-btn>
        </v-layout>
      </div>
    </v-container>
    <transition name="fade">
      <v-container fluid v-show="noMatch">
        <div class="container">
          <v-img :src="noMatchImage.src"></v-img>
          <v-layout justify-center>
            <v-btn large class="searchButton primary--text" color="secondary" @click="resetInput">{{$t('licenseplate.back')}}</v-btn>
          </v-layout>
        </div>
      </v-container>
    </transition>
  </v-layout>

</template>

<script>
  import Scanner from '../../components/scan/car-scanner';
  import PersonCard from '../../components/person-card/person-card';
  import { ACTIONS } from '../persons/persons.constants';
  import { mapActions, mapState } from 'vuex';

  export default {
    name: 'licence-plate',
    components: {
      PersonCard,
      Scanner
    },
    data() {
      return {
        licensePlate: '',
        field: 'licensePlate',
        scanImage: { src: require('@/assets/scanner/scan-cars.svg') },
        noMatchImage: { src: require('@/assets/general/no-match.png') },
      };
    },
    computed: {
      ...mapState({
        loading: state => state.persons.loading,
        person: state => state.persons.person,
        noMatch: state => state.persons.noMatch
      }),
      personData() {
        return {
          title: this.person.licensePlate,
          subTitle: this.person.vehicle,
          data: {
            color: this.person.vehicleColor,
            'registered to': `${this.person.firstName} ${this.person.lastName}`,
            'address of owner': `${this.person.street} ${this.person.houseNr}, ${this.person.zip} ${this.person.town}`
          },
          img: require(`@/assets/cars/${this.person._id}.png`)
        };
      }
    },
    destroyed() {
      this.reset();
    },
    methods: {
      ...mapActions({
        searchPerson: ACTIONS.GET_PERSON,
        reset: ACTIONS.RESET_PERSON,
      }),
      getPerson: function () {
        this.searchPerson({
          field: this.field,
          search: this.licensePlate
        });
      },
      resetInput() {
        this.licensePlate = '';
        this.reset();
      }
    }
  };
</script>

<style lang="scss" scoped>
  .fade-enter-active {
    transition: opacity 0.5s ease-in-out;
  }

  .fade-enter-to {
    opacity: 1;
  }

  .fade-enter {
    opacity: 0;
  }


  .licenseplate-title {
    font-size: 2vw;
  }

  .searchButton {
    height: 70px;
    width: 150px;
    font-size: 20px;
  }

  .container {
    width: 100%;
    margin: 20px auto;
  }

  @media screen and (min-width: 1900px) {
    .container {
      width: 74%;
      margin: 20px auto;
    }
  }

  .licenseplate {
    color: #78000e !important;
    background-color: #fff;
    background-image: url(../../assets/licensePlate/license-plate.svg);
    background-size: cover;
    background-repeat: no-repeat;
    font-family: "Segoe UI", Arial, sans-serif;
    font-size: 5vw;
    line-height: 5vw;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    padding-left: 60px;
    padding-bottom: 0px;
    width: 100%;
    height: 140px;
    border: 5px solid #78000e !important;
    border-radius: 15px;
    outline: none;

    &::placeholder {
      color: #eee;
    }
  }
</style>
