export const MODULE_NAME = 'persons';

export const ACTIONS = {
  GET_PERSON: `${MODULE_NAME}/GET_PERSON`,
  RESET_PERSON: `${MODULE_NAME}/RESET_PERSON`,
  SET_NO_MATCH: `${MODULE_NAME}/SET_NO_MATCH`,
};


export const MUTATIONS = {
  SET_PERSON: `${MODULE_NAME}/SET_PERSON`,
  SET_LOADING: `${MODULE_NAME}/SET_LOADING`,
  RESET_PERSON: `${MODULE_NAME}/RESET_PERSON`,
  SET_NO_MATCH: `${MODULE_NAME}/SET_NO_MATCH`,
};
