export const MODULE_NAME = 'game';

export const ACTIONS = {
  GET_GAME: `${MODULE_NAME}/GET_GAME`,
};


export const MUTATIONS = {
  SET_GAME: `${MODULE_NAME}/SET_GAME`,
  SET_LOADING: `${MODULE_NAME}/SET_LOADING`,
};
