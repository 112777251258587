<template>
  <MglMarker :coordinates="coordinates" color="blue">
    <img slot="marker" :src="require('@/assets/marker.svg')" width="50px"/>
  </MglMarker>
</template>

<script>
  import { mapState } from 'vuex';
  import { MglMarker } from 'vue-mapbox';
	export default {
		name: 'mapMarker',
    components: {
      MglMarker,
    },
    computed: {
      ...mapState({
        coordinates: state => state.route.marker,
      })
    },
	};
</script>

<style scoped>

</style>
