export const MODULE_NAME = 'route';

export const ACTIONS = {
  START_GAME: `${MODULE_NAME}/START_GAME`,
  END_GAME: `${MODULE_NAME}/END_GAME`,
  GET_ROUTE: `${MODULE_NAME}/GET_ROUTE`,
  SET_DIALOG: `${MODULE_NAME}/SET_DIALOG`,
  CALCULATE_ROUTE: `${MODULE_NAME}/CALCULATE_ROUTE`,
  SET_LAST_COORDINATES: `${MODULE_NAME}/SET_LAST_COORDINATES`,
  SET_START_HOUR: `${MODULE_NAME}/SET_START_HOUR`,
  SET_END_HOUR: `${MODULE_NAME}/SET_END_HOUR`,
  SET_LAST_LOCATION: `${MODULE_NAME}/SET_LAST_LOCATION`,
  CHECK_LOCATION: `${MODULE_NAME}/CHECK_LOCATION`,
  NAVIGATE_ROUTE: `${MODULE_NAME}/NAVIGATE_ROUTE`,
  SET_MARKER: `${MODULE_NAME}/SET_MARKER`,
  SET_COLLECTED: `${MODULE_NAME}/SET_COLLECTED`,
};


export const MUTATIONS = {
  SET_START_HOUR: `${MODULE_NAME}/SET_START_HOUR`,
  SET_END_HOUR: `${MODULE_NAME}/SET_END_HOUR`,
  SET_DIALOG: `${MODULE_NAME}/SET_DIALOG`,
  SET_ROUTE: `${MODULE_NAME}/SET_ROUTE`,
  SET_MARKER: `${MODULE_NAME}/SET_MARKER`,
  SHIFT_POINT: `${MODULE_NAME}/SHIFT_POINT`,
  SET_LAST_LOCATION: `${MODULE_NAME}/SET_LAST_LOCATION`,
  SET_COLLECTED: `${MODULE_NAME}/SET_COLLECTED`,
};
