const DEFAULT_DISMISS_TIMEOUT = 5000;
let maxToastId = 0;

const toast = {
	namespaced: true,
	state: {
		messages: [],
	},
	actions: {
		makeToast({ commit, dispatch }, {
			message,
			params,
			type = 'success',
			icon = 'check',
			actions,
			autoDismiss = true,
			dismissAfter = DEFAULT_DISMISS_TIMEOUT,
		}) {
			maxToastId += 1;
			const id = maxToastId;

			commit('addToast', {
				id,
				message,
				params,
				icon,
				type,
				actions,
			});

			if (autoDismiss) {
				setTimeout(() => {
					dispatch('removeToast', id);
				}, dismissAfter);
			}
		},
		removeToast({ commit }, id) {
			commit('removeToast', id);
		},
	},
	mutations: {
		addToast(state, message) {
			state.messages.push(message);
		},
		removeToast(state, id) {
			state.messages = state.messages.filter(m => m.id !== id);
		},
	},
};


export default toast;
