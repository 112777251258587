import * as toast from '../components/toast';
import * as route from '../views/map/routes';
import * as authentication from '../auth';
import * as persons from '../views/persons';
import * as games from '../views/game';

const modules = {
  toast,
  authentication,
  route,
  persons,
  games,
};

export default modules;
