export const MODULE_NAME = 'authentication';


export const GETTERS = {
  LOGGED_IN: `${MODULE_NAME}/LOGGED_IN`,
};

export const ACTIONS = {
  CHECK_USER: `${MODULE_NAME}/CHECK_USER`,
  SET_USER_NAME: `${MODULE_NAME}/SET_USER_NAME`,
  CHECK_TOKEN: `${MODULE_NAME}/CHECK_TOKEN`,
  SET_CURRENT_USER: `${MODULE_NAME}/SET_CURRENT_USER`,
  GET_ME: `${MODULE_NAME}/GET_ME`,
  LOGOUT: `${MODULE_NAME}/LOGOUT`,
  LOGIN: `${MODULE_NAME}/LOGIN`,
  RESET_PASSWORD: `${MODULE_NAME}/RESET_PASSWORD`,
  MAIL_RESET_PASSWORD: `${MODULE_NAME}/MAIL_RESET_PASSWORD`,
  ADD_ROUTE_TO_USER: `${MODULE_NAME}/ADD_ROUTE_TO_USER`,
};


export const MUTATIONS = {
  LOGOUT: `${MODULE_NAME}/LOGOUT`,
  SET_USER: `${MODULE_NAME}/SET_USER`,
  SET_INITIAL_USER: `${MODULE_NAME}/SET_INITIAL_USER`,
  SET_TOKEN_VALID: `${MODULE_NAME}/SET_TOKEN_VALID`,
  SET_CREDENTIALS: `${MODULE_NAME}/SET_CREDENTIALS`,
  ADD_ROUTE_TO_USER: `${MODULE_NAME}/ADD_ROUTE_TO_USER`,
};
