<template>
  <v-layout>
    <v-container class="tracking" fluid v-if="!noMatch && !loading && !person ">
      <h1 class="text-md-center barcode-title">{{$t('post.title')}}</h1>
      <barcode :value="barcode"></barcode>
      <input type="text" v-model="barcode" name="barcode" class="barcode mt-10" maxlength="15" @keydown.enter="getPerson"
             placeholder="1566845"/>

      <v-btn large class="mt-10 searchButton primary--text" color="secondary" :disabled="!barcode" @click="getPerson">{{$t('post.search')}}
      </v-btn>
    </v-container>
    <v-container fluid v-if="loading">
      <h1 class="text-md-center barcode-title">{{$t('post.searching')}}</h1>
      <div class="container">
        <scanner :logo="scanImage"></scanner>
      </div>
    </v-container>
    <v-container fluid v-if="person">
      <h1 class="text-md-center barcode-title">{{$t('post.match')}}</h1>
      <div class="container">

        <person-card :data="personData.data" :title="personData.title" :sub-title="personData.subTitle"
                     :img="personData.img"></person-card>
        <v-layout justify-center>
          <v-btn large class="searchButton primary--text" color="secondary" @click="resetInput">{{$t('back')}}</v-btn>
        </v-layout>
      </div>
    </v-container>
    <transition name="fade">
      <v-container fluid v-show="noMatch">
        <div class="container">
          <v-img :src="noMatchImage.src"></v-img>
          <v-layout justify-center>
            <v-btn large class="searchButton primary--text" color="secondary" @click="resetInput">{{$t('back')}}</v-btn>
          </v-layout>
        </div>
      </v-container>
    </transition>
  </v-layout>

</template>

<script>
  import Scanner from '../../components/scan/car-scanner';
  import PersonCard from '../../components/person-card/person-card';
  import VueBarcode from 'vue-barcode';
  import { ACTIONS } from '../persons/persons.constants';
  import { mapActions, mapState } from 'vuex';

  export default {
    name: 'licence-plate',
    components: {
      PersonCard,
      Scanner,
      'barcode': VueBarcode
    },
    data() {
      return {
        barcode: '',
        field: 'barcode',
        scanImage: { src: require('@/assets/scanner/scan-package.svg') },
        noMatchImage: { src: require('@/assets/general/no-match.png') },
      };
    },
    computed: {
      ...mapState({
        loading: state => state.persons.loading,
        person: state => state.persons.person,
        noMatch: state => state.persons.noMatch
      }),
      personData() {
        return {
          title: this.person.barcode,
          subTitle: this.person.packageStatus,
          data: {
            'time of delivery': this.person.deliveryDay,
            'to': `${this.person.firstName} ${this.person.lastName}`,
            'address of delivery': `${this.person.street} ${this.person.houseNr}, ${this.person.zip} ${this.person.town}`
          },
          img: require(`@/assets/general/package.png`)
        };
      }
    },
    destroyed() {
      this.reset();
    },
    methods: {
      ...mapActions({
        searchPerson: ACTIONS.GET_PERSON,
        reset: ACTIONS.RESET_PERSON,
      }),
      getPerson: function () {
        this.searchPerson({
          field: this.field,
          search: this.barcode
        });
      },
      resetInput() {
        this.barcode = '';
        this.reset();
      }
    }
  };
</script>

<style lang="scss">
  .fade-enter-active {
    transition: opacity 0.5s ease-in-out;
  }

  .fade-enter-to {
    opacity: 1;
  }

  .fade-enter {
    opacity: 0;
  }

  .tracking {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .barcode-title {
    font-size: 2vw;
  }

  .searchButton {
    height: 70px;
    width: 150px;
    font-size: 20px;
  }

  .container {
    width: 80%;
    margin: 20px auto;
  }

  .vue-barcode-element {
    rect {
      fill: transparent !important;
    }

    g {
      rect {
        fill: #00aafd !important;
      }
    ;
    }

    text {
      display: none;
    }
  }

  .barcode {
    color: #005778 !important;
    background-color: #fff;
    background-size: cover;
    background-repeat: no-repeat;
    font-family: "Segoe UI", Arial, sans-serif;
    font-size: 4vw;
    line-height: 4vw;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    width: 50%;
    margin: auto;
    height: 5vw;
    border-radius: 5px;
    outline: none;

    &::placeholder {
      color: #eee;
    }
  }
</style>
