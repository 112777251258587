<template>
  <div ref="navigationContainer" class="navigation pa-3">
    <div v-if="!searching">
      <h2 class="secondary--text">{{$t('phone.title')}}</h2>
      <v-text-field name="location" @keydown.enter="trackPhone" v-model="phone" color="secondary" outlined></v-text-field>
      <v-btn id="sendTeam" block color="secondary light primary--text" @click="trackPhone">{{$t('phone.track')}}</v-btn>
    </div>
    <div v-else>
      <pre v-for="message in messages" class="secondary--text" v-html="message"></pre>
      <v-btn class="mb-5 mt-5" block color="secondary light primary--text" @click="reset">{{$t('phone.reset')}}</v-btn>
    </div>

  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import { ACTIONS } from '../persons/persons.constants';

  export default {
    name: 'MapNavigation',
    data() {
      return {
        field: 'phone',
        phone: '',
        searching: false,
        messages: [],
        counter: 0,
        locationCounter: 0,
        timout: null
      };
    },
    computed: {
      ...mapState({
        coordinates: state => state.route.marker,
        person: state => state.persons.person,
        noMatch: state => state.persons.noMatch
      }),
    },
    watch: {
      noMatch() {
        this.atLocationMessage();
        this.messages.push(`Found Signal: to get accurate address we need ${3 - this.locationCounter} Singals`);
      },
      person() {
        this.atLocationMessage();
        this.messages.push(`Found Signal: to get accurate address we need ${3 - this.locationCounter} Singals`);
      }
    },
    destroyed() {
      this.reset();
    },
    methods: {
      ...mapActions({
        searchPerson: ACTIONS.GET_PERSON,
        resetPerson: ACTIONS.RESET_PERSON,
      }),
      reset() {
        this.messages = [];
        this.phone = '';
        this.searching = false;
        this.resetPerson();
      },
      async trackPhone() {
        this.searching = true;
        this.messages.push('Proto\tRecv-Q\tSend-Q\tLocal Address\t\tForeign Address\t(state)');
        this.atMessage();
        this.searchPerson({
          field: this.field,
          search: this.phone.replace('+', '')
        });

      },
      atLocationMessage() {
        if (this.locationCounter < 1) {
          setTimeout(() => {
            this.messages.push(`Found Signal: to get accurate address we need ${2 - this.locationCounter} Singals`);
            this.atLocationMessage();
            this.locationCounter += 1;
            this.$refs.navigationContainer.scrollTop = this.$refs.navigationContainer.scrollHeight -  this.$refs.navigationContainer.clientHeight;
          }, 5000);
        } else {
          setTimeout(() => {
            clearTimeout(this.timout);
            if (this.person) {
              this.messages.push(`Found Address: ${this.person.street} ${this.person.houseNr}, ${this.person.zip} ${this.person.town}`);
            } else {
              this.messages.push(`No Address Found: Signal lost`);
            }
            this.$refs.navigationContainer.scrollTop = this.$refs.navigationContainer.scrollHeight -  this.$refs.navigationContainer.clientHeight;
          }, 5000);
        }
      },
      atMessage() {
        if (this.counter < 100) {
          this.timout = setTimeout(() => {
            this.messages.push(`tcp${(Math.floor(Math.random() * 1) === 0 ? 4 : 6)}\t${Math.floor(Math.random() * 10)}\t${Math.floor(Math.random() * 10)}\t${this.randomIp()}\t\t${this.randomIp()}\tESTABLISHED`);
            this.counter += 1;
            this.atMessage();
            this.$refs.navigationContainer.scrollTop = this.$refs.navigationContainer.scrollHeight -  this.$refs.navigationContainer.clientHeight;
          }, Math.floor(Math.random() * 1000) + 200);

        }
      },
      randomIp() {
        return (Math.floor(Math.random() * 255) + 1) + '.' + (Math.floor(Math.random() * 255)) + '.' + (Math.floor(Math.random() * 255)) + '.' + (Math.floor(Math.random() * 255));
      }
    }
  };
</script>

<style lang="scss">
  @import "@/styles/colors";

  .navigation {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 45%;
    height: auto;
    padding-bottom: 20px;
    overflow: scroll;
    max-height: 50%;
    background-color: rgba(0, 64, 95, 0.87);
    border: 1px solid $secondary;

    p {
      line-height: 15px;
    }
  }

  input {
    color: $secondary !important;
  }

  fieldset {
    border-color: $secondary !important;
  }

  #sendTeam {
    margin-top: -20px;
  }
</style>
