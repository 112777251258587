import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
	  dark: true,
		themes: {
			light: {
				primary: '#0f151d',
				secondary: '#0081c0',
				accent: '#0081c0',
				error: '#005778',
			},
      dark: {
        primary: '#00060d',
        secondary: '#f7f3f2',
        accent: '#eaeaf1',
        error: '#005778',
      },
		},
	},
});
