<template>
  <div id="draw">
    <canvas id="canvas">
    </canvas>
    <div class="cursor" id="cursor"></div>
    <div class="controls">
      <div class="btn-row">
        <button type="button"
                @click="removeAllHistory"
                :class="{ disabled: !history.length }" title="Clear all">
          <v-icon x-large color="grey" class="mdi mdi-delete"></v-icon>
        </button>
      </div>

      <div class="btn-row">
        <button title="Pick a color"
                @click="popups.showColor = !popups.showColor"
                :class="{ active: popups.showColor }">
          <v-icon x-large color="grey" class="mdi mdi-palette"></v-icon>
          <span class="color-icon" :style="{backgroundColor: color}">
					</span>
        </button>

        <div class="popup" v-if="popups.showColor">
          <div class="popup-title">
            Brush color
          </div>
          <label v-for="colorItem in colors" class="color-item">
            <input type="radio"
                   name="color"
                   :value="colorItem"
                   @click="selectColor(colorItem)"
            >
            <span :class="'color color-' + colorItem"
                  :style="{backgroundColor: colorItem}"
                  @click="selectColor(colorItem)"></span>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'draw-page',
    data() {
      return {
        history: [],
        tempHistory: [],
        color: '#13c5f7',
        mouseX: 0,
        mouseY: 0,
        mouseDown: false,
        popups: {
          showColor: false,
        },
        size: 6,
        colors: [
          '#16cb05',
          '#1fb8f7',
          '#f713e0',
          '#7605d8',
          '#f71313',
          '#402606',
          '#c6650b',
          '#fcc816',
          '#e2dfde'],
      };
    },
    mounted() {
      this.c = document.getElementById('canvas');
      this.ctx = this.c.getContext('2d');

      this.mouseX = 0;
      this.mouseY = 0;

      this.tempHistory = [];

      this.setSize();

      this.listen();

      this.redraw();
    },
    methods: {
      selectColor(item) {
        this.color = item;
        this.popups.showColor = false;
      },
      listen() {
        this.c.addEventListener('mousedown', (e) => {
          this.mouseDown = true;
          this.mouseX = e.offsetX;
          this.mouseY = e.offsetY;
          this.setDummyPoint();
        });

        this.c.addEventListener('mouseup', () => {
          if (this.mouseDown) {
            this.setDummyPoint();
          }
          this.mouseDown = false;
        });

        this.c.addEventListener('mouseleave', () => {
          if (this.mouseDown) {
            this.setDummyPoint();
          }
          this.mouseDown = false;
        });

        this.c.addEventListener('mousemove', (e) => {
          this.moveMouse(e);

          if (this.mouseDown) {
            this.mouseX = e.offsetX;
            this.mouseY = e.offsetY;

            var item = {
              isDummy: false,
              x: this.mouseX,
              y: this.mouseY,
              c: this.color,
              r: this.size
            };

            this.history.push(item);
            this.draw(item, this.history.length);
          }
        });

        window.addEventListener('resize', () => {
          this.setSize();
          this.redraw();
        });
      },

      setSize() {
        this.c.width = window.innerWidth;
        this.c.height = window.innerHeight - 100;
      },

      moveMouse(e) {
        let x = e.offsetX;
        let y = e.offsetY;

        var cursor = document.getElementById('cursor');

        cursor.style.transform = `translate(${x - 10}px, ${y - 10}px)`;
      },

      getDummyItem() {
        var lastPoint = this.history[this.history.length - 1];
        if (!lastPoint) {
          lastPoint = {
            x: this.mouseX,
            y: this.mouseY,
          };
        }

        return {
          isDummy: true,
          x: lastPoint.x,
          y: lastPoint.y,
          c: null,
          r: null
        };
      },

      setDummyPoint() {
        var item = this.getDummyItem();
        this.history.push(item);
        this.draw(item, this.history.length);
      },

      redraw() {
        this.ctx.clearRect(0, 0, this.c.width, this.c.height);
        this.drawBgDots();

        if (!this.history.length) {
          return true;
        }

        this.history.forEach((item, i) => {
          this.draw(item, i);
        });
      },

      drawBgDots() {
        var gridSize = 50;
        this.ctx.fillStyle = 'rgba(0, 0, 0, .2)';

        for (var i = 0; i * gridSize < this.c.width; i++) {
          for (var j = 0; j * gridSize < this.c.height; j++) {
            if (i > 0 && j > 0) {
              this.ctx.beginPath();
              this.ctx.rect(i * gridSize, j * gridSize, 2, 2);
              this.ctx.fill();
              this.ctx.closePath();
            }
          }
        }
      },

      draw(item, i) {
        this.ctx.lineCap = 'round';
        this.ctx.lineJoin = 'round';

        var prevItem = this.history[i - 2];

        if (i < 2) {
          return false;
        }

        if (!item.isDummy && !this.history[i - 1].isDummy && !prevItem.isDummy) {
          this.ctx.strokeStyle = item.c;
          this.ctx.lineWidth = item.r;

          this.ctx.beginPath();
          this.ctx.moveTo(prevItem.x, prevItem.y);
          this.ctx.lineTo(item.x, item.y);
          this.ctx.stroke();
          this.ctx.closePath();
        } else if (!item.isDummy) {
          this.ctx.strokeStyle = item.c;
          this.ctx.lineWidth = item.r;

          this.ctx.beginPath();
          this.ctx.moveTo(item.x, item.y);
          this.ctx.lineTo(item.x, item.y);
          this.ctx.stroke();
          this.ctx.closePath();
        }
      },
      removeAllHistory() {
        this.history = [];
        this.redraw();
      },
      simplify() {
        var simpleHistory = [];
        this.history.forEach((item, i) => {
          if (i % 6 !== 1 || item.isDummy) {
            simpleHistory.push(item);
          }
        });
        this.history = simpleHistory;
        this.redraw();
      },
    }
  };
</script>

<style lang="scss" scoped>
  @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');

  $prim: rgb(0, 149, 255);

  #draw {
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .text-faded {
    opacity: .5;
  }

  .cursor {
    position: fixed;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 3px solid rgb(30, 30, 30);
    pointer-events: none;
    user-select: none;
    mix-blend-mode: difference;
    opacity: 0;
    transition: opacity 1s;
  }

  canvas {
    background-image: url("../../assets/bg/festival.png");
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: calc(100vh - 100px);
    background-color: white;
    cursor: none;

    &:hover + .cursor {
      opacity: 1;
    }

    &:active + .cursor {
      border-color: rgb(60, 60, 60);
    }
  }

  .controls {
    position: fixed;
    z-index: 5;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background-color: rgb(10, 10, 10);
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
  }

  .stat {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .btn-row {
    position: relative;
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0 15px;
    border-radius: 4px;
  }

  .popup {
    position: absolute;
    width: 300px;
    bottom: 80px;
    padding: 30px;
    left: calc(50% - 150px);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: white;
    color: rgb(30, 30, 30);
    border-radius: 10px 10px 0 0;
    border: 1px solid rgb(220, 220, 220);
    border-bottom-width: 0;
    opacity: 0;
    animation: popup .5s forwards cubic-bezier(.2, 2, .4, 1);
    z-index: 2;
    overflow: hidden;
    max-height: 80vh;
    overflow-y: auto;

    .popup-title {
      flex: 0 0 100%;
      text-align: center;
      font-size: 16px;
      color: black;
      opacity: .5;
      margin-bottom: 10px;
    }

    button {
      height: 80px;
      width: 80px;
      text-align: center;
      font-size: 14px;
      color: rgba(0, 0, 0, .4);

      i {
        display: block;
        font-size: 30px;
        margin-bottom: 5px;
        color: rgba(0, 0, 0, .2);
      }

      &.disabled {
        color: rgba(0, 0, 0, .2);

        i {
          color: rgba(0, 0, 0, .1);
        }
      }

      &.active,
      &:active {
        color: rgba(0, 0, 0, .4);

        i {
          color: $prim;
        }
      }
    }
  }

  @keyframes popup {
    from {
      opacity: 0;
      transform: translateX(40px);
    }
    to {
      opacity: 1;
      transform: none;
    }
  }

  .welcome-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    background-color: $prim;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .fade-up {
    opacity: 0;
    animation: fade-up 1s forwards cubic-bezier(.2, 2, .4, 1);
  }

  .btn {
    display: inline-block;
    margin-top: 10px;
    padding: 10px 20px;
    font-weight: 400;
    font-size: 16px;
    border-radius: 4px;
    background-color: $prim;
    color: white;
    animation-delay: 1s;
    transition: all .15s;
    cursor: pointer;

    &:hover {
      background-color: lighten($prim, 10%);
    }
  }

  .welcome {
    width: 400px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h1.fade-up {
      font-weight: 300;
      font-size: 40px;
      animation-delay: .25s;
    }

    h2.fade-up {
      font-weight: 400;
      color: rgba(255, 255, 255, .5);
      animation-delay: .5s;
    }

    a.fade-up {
      color: rgba(255, 255, 255, .5);
      display: inline-block;
      margin-top: 20px;
      text-decoration: none;
      animation-delay: .75s;
    }

    .btn.fade-up {
      background-color: rgba(255, 255, 255, .2);
      color: white;
      margin-top: 60px;

      &:hover {
        background-color: rgba(255, 255, 255, .3);
      }
    }
  }


  @keyframes fade-up {
    from {
      transform: translateY(80px);
      opacity: 0;
    }
    to {
      transform: none;
      opacity: 1;
    }
  }

  .form {
    flex: 0 0 100%;

    input {
      display: block;
      thisearance: none;
      border: 0;
      box-shadow: 0;
      outline: 0;
      background-color: rgb(240, 240, 240);
      border-radius: 4px;
      padding: 10px 15px;
      width: 100%;
      margin-bottom: 4px;
    }
  }

  button {
    thisearance: none;
    border: 0;
    border-radius: 0;
    box-shadow: 0;
    width: 40px;
    height: 60px;
    display: inline-block;
    background-color: transparent;
    color: rgb(140, 140, 140);
    font-size: 22px;
    transition: all .15s;
    cursor: pointer;
    outline: 0;
    position: relative;

    .size-icon,
    .color-icon {
      position: absolute;
      top: 10px;
      right: 0;
    }

    .color-icon {
      width: 5px;
      height: 5px;
      border-radius: 50%;
    }

    .size-icon {
      font-size: 6px;
      text-align: right;
    }

    &:hover {
      opacity: .8;
    }

    &:active,
    &.active {
      color: white;
    }

    &.disabled {
      color: rgb(50, 50, 50);
      cursor: not-allowed;
    }
  }

  .history {
    width: 30px;
    height: 30px;
    background-color: rgb(30, 30, 30);
    border-radius: 50%;
    text-align: center;
    line-height: 30px;
    font-size: 12px;
    overflow: hidden;
    color: rgb(140, 140, 140);
  }

  .color-item {
    position: relative;
    display: inline-block;
    cursor: pointer;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

    input {
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
    }

    input:checked + .color {
      opacity: 1;
      border: 2px solid $prim;
    }

    .color {
      display: block;
      width: 30px;
      height: 30px;
      background-color: white;
      border-radius: 50%;

      &:hover {
        opacity: .8;
      }
    }
  }

  @keyframes pulsate {
    0%,
    100% {
      transform: none;
    }
    50% {
      transform: scale(1.15);
    }
  }

  .size-item {
    width: 40px;
    height: 60px;
    display: inline-flex;
    position: relative;
    justify-content: center;
    align-items: center;
    vertical-align: top;
    cursor: pointer;

    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      opacity: 0;
    }

    .size {
      background-color: rgb(140, 140, 140);
      display: inline-block;
      border-radius: 50%;
      transition: all .15s;
      transform: translate(-50%, -50%) scale(.6);
      position: absolute;
      top: 50%;
      left: 50%;

      &:hover {
        opacity: .8;
      }
    }

    input:checked + .size {
      background-color: $prim;
    }
  }
</style>
