import api from '../../services/backendApi';
import { ACTIONS, MUTATIONS } from './game.constants';
import Vue from 'vue';

const route = {
  state: {
    game: null,
  },
  actions: {
    async [ACTIONS.GET_GAME]({ commit }) {
      try {
        commit(MUTATIONS.SET_LOADING, true);
        const response = await api.get(`${process.env.VUE_APP_API_URL}/games`);
        commit(MUTATIONS.SET_GAME, response.data);
        commit(MUTATIONS.SET_LOADING, false);
        return response.data;
      } catch (e) {
        commit(MUTATIONS.SET_LOADING, false);
        return null;
      }
    },
  },
  mutations: {
    [MUTATIONS.SET_GAME](state, response) {
      Vue.set(state, 'game', response);
    },
    [MUTATIONS.SET_LOADING](state, response) {
      state.loading = response;
    },
  },
};

export default route;
