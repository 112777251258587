<template>
  <MglMarker :coordinates="marker.coordinates" color="blue" @click="popupLocation">
    <img slot="marker" :src="require('@/assets/location/investigation.svg')" width="50px"/>
  </MglMarker>
</template>

<script>
  import { mapActions } from 'vuex';
  import { ACTIONS} from './routes/routes.constants';
  import { MglMarker } from 'vue-mapbox';
	export default {
		name: 'mapLocations',
    props: {
      marker: Object,
    },
    components: {
      MglMarker,
    },
    methods: {
      ...mapActions({
        setDialog: ACTIONS.SET_DIALOG,
        checkLocation: ACTIONS.CHECK_LOCATION
      }),
		  popupLocation() {
        this.checkLocation(this.marker.coordinates);
      }
    }
	};
</script>

<style scoped>

</style>
