<template>
	<div class="v-toast-container toast-position-ne">
		<transition-group name="toast" tag="div">
			<div class="toast-wrapper" v-for="message in messages" :key="message.id">
				<toast
						:message="message.message"
						:params="message.params"
						:type="message.type"
						:icon="message.icon"
						:actions="message.actions"
						@close="close(message.id)"></toast>
			</div>
		</transition-group>
	</div>
</template>

<script>
	import { mapState, mapActions } from 'vuex';
	import toast from './toast';

	export default {
		name: 'ToastContainer',
		components: {
			toast
		},
		computed: {
			...mapState({
				messages: state => state.toast.messages,
			}),
		},
		methods: {
			...mapActions({
				close: 'toast/removeToast',
			}),
		},
	};
</script>

<style scoped>
	.v-toast-container {
		position: fixed;
		width: 350px;
		z-index: 10000;
	}

	.toast-position-ne {
		top: 16px;
		right: 16px;
	}

	/* Transitions */

	.toast-wrapper {
		width: 350px;
		transition: 400ms cubic-bezier(0.17, 0.67, 0.17, 0.98);
		transition-property: opacity, transform;
	}

	.toast-enter-active,
	.toast-leave {
		opacity: 1;
	}

	.toast-enter,
	.toast-leave-active {
		opacity: 0;
	}

	.toast-leave-active {
		position: absolute;
	}

	.toast-enter, .toast-leave-active {
		transform: translateY(-20px);
	}
</style>
