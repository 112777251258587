<template>
  <MglMarker :coordinates="marker" color="blue">
    <div slot="marker" class="dot" :class="{color: color}">
      <div class="centraldot"></div>
      <div class="wave"></div>
      <div class="wave2"></div>
    </div>
  </MglMarker>
</template>

<script>
  import { mapState } from 'vuex';
  import { MglMarker } from 'vue-mapbox';

  export default {
    name: 'mapMarker',
    props: {
      marker: {
        type: Array,
      },
      color: {
        type: Boolean
      }
    },
    components: {
      MglMarker,
    },
  };
</script>

<style lang="scss" scoped>
  .dot {
    margin: auto auto;
    width: 300px;
    height: 300px;
  }

  .color {
    .centraldot {
      background: rgb(120, 0, 14);
      animation: animationDotCentralColor linear 5s;
    }

    .wave {
      background: rgba(243, 0, 7, 0.4);
      animation: animationWaveColor cubic-bezier(0, .54, .53, 1) 5s;
    }

    .wave2 {
      background: rgba(243, 15, 10, 0.4);
      animation: animationWaveColor cubic-bezier(0, .54, .53, 1) 5s;
    }

  }


  .centraldot {
    width: 6px;
    height: 6px;
    background: rgba(32, 150, 243, 1);
    border-radius: 30px;
    position: absolute;
    left: 147px;
    top: 147px;
    animation: animationDotCentral linear 15s;
    transform-origin: 50% 50%;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
  }


  .wave {
    width: 260px;
    height: 260px;
    background: rgba(32, 150, 243, 0.4);
    border-radius: 200px;
    position: absolute;
    left: 20px;
    top: 20px;
    opacity: 0;
    animation: animationWave cubic-bezier(0, .54, .53, 1) 15s;
    transform-origin: 50% 50%;
    animation-fill-mode: forwards;
    animation-delay: 0.9s;
    animation-iteration-count: infinite;
  }

  .wave2 {
    width: 260px;
    height: 260px;
    background: rgba(32, 150, 243, 0.4);
    border-radius: 200px;
    position: absolute;
    left: 20px;
    top: 20px;
    opacity: 0;
    animation: animationWave cubic-bezier(0, .54, .53, 1) 15s;
    transform-origin: 50% 50%;
    animation-fill-mode: forwards;
    animation-delay: 1.07s;
    animation-iteration-count: infinite;
  }


  @keyframes animationDotCentral {

    0% {
      transform: scale(0);
      opacity: 0;
    }

    10% {
      transform: scale(0.90);
      opacity: 1;
    }

    28% {
      background: rgba(32, 150, 243, 1);
    }

    29% {
      background: rgba(255, 255, 255, 1);
    }

    31% {
      background: rgba(32, 150, 243, 1);
    }

    33% {
      background: rgba(255, 255, 255, 1);
    }

    35% {
      background: rgba(32, 150, 243, 1);
    }

    90% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  @keyframes animationWave {
    0% {
      opacity: 0;
      transform: scale(0.00);
    }

    1% {
      opacity: 1;
    }

    10% {
      background: rgba(32, 150, 243, 0.4);
    }

    100% {
      transform: scale(1);
      background: rgba(32, 150, 243, 0.0);
    }
  }


  @keyframes animationDotCentralColor {

    0% {
      transform: scale(0);
      opacity: 0;
    }

    5% {
      transform: scale(2);
    }

    10% {
      transform: scale(0.90);
      opacity: 1;
    }


    11% {
      transform: scale(1.50);
    }

    12% {
      transform: scale(1.00);
    }

    28% {
      background: rgb(120, 0, 14);
    }

    29% {
      background: rgba(255, 255, 255, 1);
    }

    31% {
      background: rgb(120, 0, 14);
    }

    33% {
      background: rgba(255, 255, 255, 1);
    }

    35% {
      background: rgb(120, 0, 14);
    }

    90% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  @keyframes animationWaveColor {
    0% {
      opacity: 0;
      transform: scale(0.00);
    }

    1% {
      opacity: 1;
    }

    10% {
      background: rgba(243, 15, 24, 0.8);
    }

    100% {
      transform: scale(1);
      background: rgba(243, 15, 24, 0.4);
    }
  }
</style>
