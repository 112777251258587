<template>
  <v-app-bar app color="transparent" class="elevation-0" dark>
    <v-spacer></v-spacer>
    <div class="d-flex align-center ma-1">
  
      <h2 v-if="currentLocation > 0" class="mr-5">{{$t('header.currentLocation')}} {{currentLocation}} </h2>
      <h2 v-if="currentCollect > 0" class="mr-5">{{$t('header.currentCollect')}} {{currentCollect}} </h2>
    </div>

    <v-btn id="hints" to="/hints" class="mx-2 mr-8" fab dark small color="secondary">
      <v-icon color="primary">mdi-comment-question-outline</v-icon>
    </v-btn>

    <div class="d-flex align-center ma-1">
      <v-icon color="secondary" x-large>mdi-clock-fast</v-icon>
      <v-divider
        dark
        class="mx-4"
        vertical
      ></v-divider>
      <h2 id="timeLeft" class="mr-5"> {{ time}} </h2>
    </div>
    <div class="d-flex align-center ma-1">
      <v-icon color="secondary" x-large>mdi-fingerprint</v-icon>
      <v-divider
        dark
        class="mx-4"
        vertical
      ></v-divider>
    </div>
    <h2 class="mr-5 text-uppercase"> {{$t('header.agent')}} {{ username}} </h2>
  </v-app-bar>
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import { ACTIONS } from '../../views/map/routes/routes.constants'

  export default {
    name: 'AppHeader',
    data() {
      return {
        time: null,
        currIEZoom: 100,
      };
    },
    computed: {
      ...mapState({
        username: state => state.authentication.user.username,
        startDate: state => state.route.startHour,
        dialog: state => state.route.dialog,
        currentLocation: state => state.route.lastLocation,
        currentCollect: state => state.route.collected,
        endDate: state => state.route.endHour,
        game: state => state.game.game,
      }),
    
    },
    watch: {
      game() {
        this.setTime();
        setInterval(this.setTime, 1000);
      }
    },
    methods: {
      ...mapActions({
           stopGame: ACTIONS.END_GAME,
      }),
      setTime: function () {
        let date = '2:00:00';
        if (this.startDate) {
          let duration = !this.endDate ?
            new Date(this.startDate).getTime() + this.game.duration * 60000 - new Date().getTime() :
            new Date(this.startDate).getTime() + this.game.duration * 60000 - new Date(this.endDate).getTime();
          if (duration < 0) {
            date = '0:00:00';
            if (!this.endDate) {
              this.stopGame();
              this.$router.push('/endPage');
            }
          } else {
            var seconds = Math.floor((duration / 1000) % 60),
              minutes = Math.floor((duration / (1000 * 60)) % 60),
              hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

            if (hours === 1 ? minutes % 10 === 0 && seconds === 0 : minutes % 20 === 0 && seconds === 0) {
              this.playSound(require('../../assets/sounds/newLocation.mp3'));
            }
            hours = '0' + hours;
            minutes = (minutes < 10) ? '0' + minutes : minutes;
            seconds = (seconds < 10) ? '0' + seconds : seconds;

            date = hours + ':' + minutes + ':' + seconds;

          }

        } else {
          const durationInMSS = this.game.duration * 60000;
          let seconds = Math.floor((durationInMSS / 1000) % 60),
            minutes = Math.floor((durationInMSS / (1000 * 60)) % 60),
            hours = Math.floor((durationInMSS / (1000 * 60 * 60)) % 24);

          hours = '0' + hours;
          minutes = (minutes < 10) ? '0' + minutes : minutes;
          seconds = (seconds < 10) ? '0' + seconds : seconds;

          date = hours + ':' + minutes + ':' + seconds;
        }
        this.time = date;
      },
      playSound(sound) {
        if (sound) {
          var audio = new Audio(sound);
          audio.play();
        }
      },
    }
  };
</script>

<style scoped>

</style>
