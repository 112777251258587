<template>
  <div class="wrapper" id="wrapper">
    <div class="modal-top">
      <v-btn to="/map" class="closeBtn mr-12" fab small top right color="grey">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <div class="content-body" id='test'>
      <svg-end-page-novartis v-if="game && (game.investigationName ==='Djuimprov_novartis' || game.investigationName ==='Djuimprov_hhc')" :investigationName="game.investigationName" :game="game.gameName" :time="time" @shareFacebook="goToFacebook()"></svg-end-page-novartis>
      <svg-end-page-diamandroof v-else-if="game && game.investigationName ==='Diamandroof'" :game="game.gameName" :collected="collected"></svg-end-page-diamandroof>
      <svg-end-page v-else-if="game" :game="game.gameName" :time="time" @shareFacebook="goToFacebook()"></svg-end-page>
    </div>
    <ShareNetwork
          v-if= "base64"
          id='facebook'
          network="facebook"
          :url="base64"
          title='test'
          hashtags="eskapo"
          class="hiden"
        >
        </ShareNetwork>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import svgEndPage from './svgEndPage.vue';
import svgEndPageNovartis from './svgEndPage-teambuilding.vue';
import svgEndPageDiamandroof from './svgEndPage-diamandroof.vue';
import http from '../../services/backendApi';

export default {
  name: 'endPage',
  components: {
    svgEndPage,
    svgEndPageNovartis,
    svgEndPageDiamandroof
  },
  data() {
    return {
      base64: `${process.env.VUE_APP_API_URL}/users/endPage/${this.user}`,
    };
  },
  computed: {
    ...mapState({
      startDate: state => state.route.startHour,
      endDate: state => state.route.endHour,
      game: state => state.game.game,
      collected: state => state.route.collected,
      user: state => state.authentication.user._id,

    }),
    time() {
      let duration = new Date(this.endDate).getTime() - new Date(this.startDate).getTime();
      var seconds = Math.floor((duration / 1000) % 60),
        minutes = Math.floor((duration / (1000 * 60)) % 60),
        hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

      hours = '0' + hours;
      minutes = (minutes < 10) ? '0' + minutes : minutes;
      seconds = (seconds < 10) ? '0' + seconds : seconds;

      return `${hours}:${minutes}:${seconds}`
    },
    title() {
      return this.$tc('endPage.facebook-title', { game: this.game.name, time: this.time })
    }
  },
  methods: {
    goToFacebook() {
      document.getElementById('facebook').click();
    },
    takePicture() {
      http.get(`${process.env.VUE_APP_API_URL}/users/endPage/${this.user}`, { responseType: 'blob' })
        .then((result) => {
          var reader = new FileReader();
          reader.readAsDataURL(result.data);
          reader.onloadend = () => {
            this.base64 = reader.result.split(',')[1];
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-image: url(../../assets/endpage/background.png);
  background-size: cover;
  z-index: 1000;
  display: flex;
  align-items: center;
}

.content-body {
  padding: 20px;
  height: 100%;
  flex: 1;
}

.closeBtn {
  position: absolute;
  top: 10px;
  right: 0;
}

.margin-top {
  margin-top: 20%;
}

.hiden {
  display: hidden;
}
</style>
