<template>
  <div class="mt-5">
    <div class="denominations" ref="denominations">
      <div class="bill denomination-100"><span>100</span></div>
      <div class="bill denomination-50"><span>50</span></div>
      <div class="bill denomination-20"><span>20</span></div>
      <div class="bill denomination-5"><span>5</span></div>
      <div class="bill denomination-1"><span>1</span></div>
    </div>
    <div class="atm">
      <div class="title">ATM</div>
      <div class="display">
        <button @click="pressedDisplayButton" value="1"><span></span></button>
        <button @click="pressedDisplayButton" value="2"><span></span></button>
        <button @click="pressedDisplayButton" value="3"><span></span></button>
        <div class="screen">
          <div ref="loading" class="loading" :class="{ active: loading }">{{$t('atm.loading')}}</div>
          <div class="message" :class="{ active: messageActive }" v-html="message"></div>
          <div ref="balance" class="balance" :class="{ active: balanceActive }">
            <span>$</span><span class="value">{{balance}}</span>
          </div>
          <div ref="input" class="input" :class="{ active: inputActive }">
            <input :value="inputText" :pattern="inputType.pattern" :type="inputType.type" disabled/>
          </div>
          <div v-if="errorActive" ref="error" :class="{ active: errorActive }">{{error}}</div>
        </div>
        <button @click="pressedDisplayButton" value="4">
          <span></span>
          <div class="option" id="option4"></div>
        </button>
        <button @click="pressedDisplayButton" value="5"><span></span>
          <div class="option" id="option5"></div>
        </button>
        <button @click="pressedDisplayButton" value="6"><span></span>
          <div class="option" id="option6"></div>
        </button>
      </div>
      <div class="instructions">
        <div ref="message" class="message">
          <span class="bolt"></span>
          <p>welcome to the Dark Ghost ATM.</p>
          <span class="bolt"></span>
        </div>
      </div>
      <div class="card-reader">
        <div class="slot">
          <div class="gap"></div>
        </div>
        <div class="card-wrapper">
          <button @click="insertCard" ref="creditcard" class="credit-card">
            <div class="card-content">
              <div class="chip"></div>
              <div class="number">0000 0000 0000 0000</div>
              <div class="name">Dark Ghost</div>
            </div>
          </button>
        </div>
      </div>
      <div class="keypad">
        <button @click="pressedKeyPad" value="1">1</button>
        <button @click="pressedKeyPad" value="2">2</button>
        <button @click="pressedKeyPad" value="3">3</button>
        <button @click="pressedKeyPad" value="cancel">Cancel</button>
        <button @click="pressedKeyPad" value="4">4</button>
        <button @click="pressedKeyPad" value="5">5</button>
        <button @click="pressedKeyPad" value="6">6</button>
        <button @click="pressedKeyPad" value="clear">Clear</button>
        <button @click="pressedKeyPad" value="7">7</button>
        <button @click="pressedKeyPad" value="8">8</button>
        <button @click="pressedKeyPad" value="9">9</button>
        <button @click="pressedKeyPad" value="enter">Enter</button>
        <button @click="pressedKeyPad" value="0">0</button>
        <button @click="pressedKeyPad" value=".">.</button>
        <button @click="pressedKeyPad" value="00">00</button>
      </div>
      <div ref="dispenser" class="cash-dispenser">
        <div class="slot">
          <div class="gap">
            <div class="door"></div>
          </div>
        </div>
        <div class="bills"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import { ACTIONS} from '../persons/persons.constants';

  export default {
    name: 'atm',
    data() {
      return {
        loading: false,
        body: {},
        inputText: '',
        inputType: {},
        messageActive: true,
        message: this.$t('atm.welcome'),
        error: '',
        inputActive: false,
        balanceActive: false,
        errorActive: false,
        balance: 66660,
        active: [],
        denominations: [100, 50, 20, 5, 1],
        functionalButtons: ['enter', 'cancel', 'clear'],
        steps: ['promptCardNumber', 'promptPin', 'transactionType', 'deposit', 'withdrawl'],
      };
    },
    destroyed() {
      this.resetPerson();
    },
    methods: {
      ...mapActions({
        searchPerson: ACTIONS.GET_PERSON,
        resetPerson: ACTIONS.RESET_PERSON,
      }),
      insertCard() {
        this.$refs.creditcard.classList.add('insert');
        setTimeout(this.load, 2000);
        setTimeout(this.promptCardNumber, 5000);
      },
      pressedKeyPad(event) {
        const target = event.target || event.srcElement;
        const value = target.value;
        console.log(value);
        if (this.functionalButtons.includes(value)) {
          switch (value) {
            case 'enter':
              this.enter();
              break;
            case 'clear':
              this.inputText = '';
              break;
            case 'cancel':
              this.restart();
              break;
          }
        } else {
          this.inputText = this.inputText + value;
        }
      },
      pressedDisplayButton(event) {
        const target = event.target || event.srcElement;
        const value = target.parentElement.value;
        if (this.currentStep === 'transactionType') {
          switch (value) {
            case '4':
              this.load();
              this.currentStep = 'withdrawal';
              setTimeout(() => {
                this.inputAmount('withdrawal');
              }, 1000);
              break;
            case '5':
              this.load();
              this.currentStep = 'deposit';
              setTimeout(() => {
                this.inputAmount('deposit');
              }, 1000);
              break;
            case '6':
              this.load();
              this.currentStep = 'userInfo';
              setTimeout(() => {
                this.getUserInfo();
              }, 1000);
              break;
          }
        }
      },
      load() {
        this.balanceActive = false;
        this.inputActive = false;
        this.messageActive = false;
        this.errorActive = false;
        this.error = '';
        this.loading = true;
        this.removeButtonText();
      },
      updateMessage(mesage) {
        this.message = mesage;
        this.messageActive = true;
        this.loading = false;
      },
      updateError(error) {
        this.error = error;
        this.errorActive = true;
        this.inputActive = true;
        this.messageActive = true;
        this.loading = false;
      },
      removeButtonText() {
        const inputButtons = document.querySelectorAll('.option');
        inputButtons.forEach((button) => {
          button.innerHTML = '';
        });
      },
      promptCardNumber() {
        this.currentStep = 'promptCardNumber';
        this.updateMessage(this.$t('atm.card-number'));
        this.inputActive = true;
        this.inputType = {
          pattern: '[0-9]{16}',
          type: 'text'
        };
      },
      validateCardNumber() {
        this.load();
        this.body.cardNumber = this.inputText;
        this.promptPin();
        this.clearInput();
      },
      promptPin() {
        this.currentStep = 'promptPin';
        this.updateMessage(this.$t('atm.pin'));
        this.inputActive = true;
        this.inputType = {
          pattern: '[0-9]{4}',
          type: 'password'
        };
      },
      async validatePin() {
        this.load();
        const res = await this.searchPerson({
          field: 'cardNumber',
          search: `${this.body.cardNumber},${this.inputText}`
        });
        if (res) {
          this.balance = res.balance;
          this.body = res;
          this.promptTransactionType();
        } else {
          this.updateError(this.$t('atm.invalid-pin'));
        }
        this.clearInput();
      },
      clearInput() {
        this.inputText = '';
        this.inputType = {
          pattern: '',
          type: 'text'
        };
      },
      getUserInfo(type) {
        this.updateMessage(`Name: ${this.body.firstName}  ${this.body.lastName}
                <br> Address: ${this.body.street}  ${this.body.houseNr}
                <br> City: ${this.body.town}`);
      },
      inputAmount(type) {
        this.updateMessage(`Enter ${type} amount.`);
        this.balanceActive = true;
        this.inputActive = true;
      },
      promptTransactionType() {
        this.currentStep = 'transactionType';
        this.updateMessage('Would you like to do?');
        this.balanceActive = true;
        document.getElementById('option4').innerHTML = this.$t('atm.withdrawal');
        document.getElementById('option5').innerHTML = this.$t('atm.deposit');
        document.getElementById('option6').innerHTML = this.$t('atm.owner-info');
      },
      makeWithdrawal() {
        this.load();
        const amount = parseInt(this.inputText);
        if (amount <= this.balance) {
          setTimeout(() => {
            this.dispenseMoney(amount);
          }, 1000);
        } else {
          setTimeout(() => {
            this.updateError('Insufficient funds');
            this.balanceActive = true;
          }, 1000);
        }
        this.clearInput();
      },
      makeDeposit() {
        this.load();
        setTimeout(() => {
          const amount = parseInt(this.inputText);
          this.clearInput();
          this.updateMessage(`Thank you for making a ${this.currentStep} of $${amount}.`);
          this.balance = this.balance + amount;
          setTimeout(this.restart, 5000);
        }, 1000);
      },
      dispenseMoney(amount) {
        this.updateMessage(`Thank you for making a ${this.currentStep} of $${amount}.`);
        this.balance = this.balance - amount;
        let total = parseInt(amount);
        const bills = this.$refs.dispenser.querySelector('.bills');

        this.denominations.forEach((denomination) => {
          const numOfBills = Math.floor(total / denomination);

          for (let i = 0; i < numOfBills; i++) {
            const bill = this.$refs.denominations.querySelector(`.denomination-${denomination}`)
              .cloneNode(true);
            bill.style.animationDelay = `${this.randomIntFromInterval(0, 1) + 1}s`;
            bill.style.animationDuration = `${this.randomIntFromInterval(1, 2) + 1}s`;
            bills.appendChild(bill);
          }
          // Get the new total
          total = total % denomination;
        });

        this.$refs.dispenser.appendChild(bills);
        this.$refs.dispenser.classList.remove('close');
        this.$refs.dispenser.classList.add('active');
        setTimeout(this.restart, 8000);
      },
      restart() {
        this.currentStep = '';
        this.updateMessage('<p>Welcome ...</p>\n' +
          '            <p>Please insert your card.</p>');
        this.balanceActive = false;
        this.inputActive = false;
        this.errorActive = false;
        this.$refs.dispenser.classList.remove('active');
        this.$refs.dispenser.classList.add('close');
        this.removeButtonText();

        this.$refs.creditcard.classList.remove('insert');
        this.$refs.creditcard.classList.add('remove');

        setTimeout(() => {
          this.$refs.creditcard.classList.remove('remove');
        }, 2000);

      },
      enter() {
        switch (this.currentStep) {
          case 'promptCardNumber':
            this.validateCardNumber();
            break;
          case 'promptPin':
            this.validatePin();
            break;
          case 'withdrawal':
            this.makeWithdrawal();
            break;
          case 'deposit':
            this.makeDeposit();
            break;
        }
      },
      randomIntFromInterval(min, max) {
        return Math.random() * (max - min + 1) + min;
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import './atm.scss';
</style>
