<template>
  <div class="navigation pa-3">
    <div v-if="!isMoving">
      <h2 class="secondary--text text-uppercase">{{$t('map.navigation.send-team-title')}}</h2>
      <v-text-field name="location" v-model="location" @keydown.enter="navigateToPoint" color="secondary" outlined ></v-text-field>
      <v-btn id="sendTeam" class="primary--text" block color="secondary" @click="navigateToPoint">{{$t('map.navigation.send-team')}}</v-btn>
    </div>
    <div v-else>
      <h2 class="secondary--text text-uppercase">{{$t('map.navigation.moving')}}</h2>
      <h2 class="secondary--text text-uppercase">{{$t('map.navigation.arrival')}}:</h2>
      <h2 class="secondary--text">{{timeTillEnd}}</h2>
    </div>

  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import { ACTIONS } from './routes/routes.constants';

  export default {
    name: 'MapNavigation',
    data() {
      return {
        location: ''
      }
    },
    computed: {
      ...mapState({
        coordinates: state => state.route.marker,
        route: state => state.route.route
      }),
      isMoving() {
        return this.route.data.geometry.coordinates.length > 1;
      },
      timeTillEnd() {
        const ms = this.route.data.geometry.coordinates.length * 100;
        const min = Math.floor((ms / 1000 / 60) << 0);
        const sec = ((ms % 60000) / 1000).toFixed(0);
        return `${min}:${sec < 10 ? '0' : ''}${sec}`;
      }
    },
    watch: {
      isMoving() {
        if (!this.isMoving) {
          this.playSound(require('../../assets/sounds/police.mp3'));
        }

      },
    },
    methods: {
      ...mapActions({
        getRoute: ACTIONS.GET_ROUTE,
      }),
      navigateToPoint() {
        this.getRoute({
          startCoordinates: this.coordinates,
          endLocation: this.location,
        });
        this.location = '';
      },
      playSound (sound) {
        if(sound) {
          var audio = new Audio(sound);
          audio.play();
        }
      }
    }
  };
</script>

<style lang="scss">
  @import "@/styles/colors";

  .navigation {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 35%;
    height: auto;
    background-color: rgba(0, 64, 95, 0.87);
    border: 1px solid $secondary;
    overflow: auto!important;
  }

  input {
    color: $secondary!important;
  }

  fieldset {
    border-color: $secondary!important;
  }

  #sendTeam {
    margin-top: -20px;
  }
</style>
