<template>
  <v-app dark>
    <toast-container></toast-container>
    <location-dialog></location-dialog>
    <tour></tour>
    <app-header v-if="showHeaderAndMenu"></app-header>
    <app-sidebar v-if="showHeaderAndMenu"></app-sidebar>
    <v-content class="content">
      <div v-if="isMobile" class="box-container-mobile text-center">
        <h2>
          {{$t("app.no-mobile")}}
        </h2>
      </div>
      <transition name="router-transition" v-else>
        <div :class="['login'].indexOf($route.name) > -1 ? '' :'box-container'">
          <keep-alive>
            <router-view v-if="['map'].indexOf($route.name) > -1"></router-view>
          </keep-alive>
          <router-view v-if="['map'].indexOf($route.name) === -1"></router-view>
        </div>
      </transition>
    </v-content>
  </v-app>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import axios from 'axios';
  import axiosInstance from './services/backendApi';
  import ToastContainer from './components/toast/toast-container';
  import { ACTIONS, GETTERS } from './auth/account.constants';
  import AppHeader from './components/main-layout/app-header';
  import AppSidebar from './components/main-layout/app-sidebar';
  import Tour from './components/tour/tour';
  import LocationDialog from './components/dialogs/dialog';

  export default {
    name: 'App',
    components: {
      LocationDialog,
      Tour,
      AppSidebar,
      AppHeader,
      ToastContainer,
    },
    data() {
      return {
        page: 'login'
      };
    },
    computed: {
      ...mapGetters({
        loggedIn: GETTERS.LOGGED_IN,
      }),
      isMobile() {
        return screen.width <= 760;
      },
      showHeaderAndMenu() {
        return this.loggedIn && this.$route.name !== 'login'
      }
    },
    watch: {
      route() {
        this.page = this.$route.name;
      }
    },
    mounted() {
      this.page = this.$route.name;
      if (this.loggedIn) {
        this.getMe();
      }
    },
    created() {
      axiosInstance.interceptors.response.use(
        response => response,
        error => new Promise(async (resolve, reject) => {
          if (error && error.response) {
            // Check if unauthenticated
            if (error.response.status === 401) {
              // Check if user was logged in
              if (await this.loggedIn) {
                // Try refreshing the expired jwt token
                this.tryRefresh()
                  .then((newJwtToken) => {
                    const requestConfig = error.config;
                    requestConfig.headers.Authorization = newJwtToken;
                    axios.request(requestConfig)
                      .then(
                        result => resolve(result),
                        retryError => reject(retryError),
                      );
                  })
                  .catch(() => {
                    this.logout();
                    this.$router.push({ name: 'login' });
                    resolve();
                  });
              } else {
                // Log out user
                this.logout();
                this.$router.push({ name: 'login' });
                resolve();
              }
            } else if (error.response.status === 403) {
              // Redirect to root / unauthorized page
              this.logout();
              this.$router.push('/login');
              resolve();
            } else {
              reject(error);
            }
          } else {
            reject(error);
          }
        }),
      );
    },
    methods: {
      ...mapActions({
        logout: ACTIONS.LOGOUT,
        tryRefresh: ACTIONS.CHECK_USER,
        getMe: ACTIONS.GET_ME,
      }),
    }
  };
</script>

<style lang="scss">
  @import "@/styles/colors.scss";
  @import "@/styles/override.scss";
  @import "@/styles/scrollbar.scss";

  html {
    background-color: #080810;
    font-family: "Share Tech Mono", monospace;
  }

  #app {
    height: 100% !important;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(0, 64, 95, 0.87);
    border: solid 3px rgba(0, 64, 95, 0.87);
    border-radius: 7px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: white;
  }

  .box-container {
    position: absolute;
    top: 90px;
    left: 20px;
    width: 97%;
    height: calc(100vh - 170px);
    background-color: rgba(0, 64, 95, 0.87) !important;
    border: 1px solid $secondary !important;
    overflow: auto !important;
  }

  .box-container-mobile {
    position: absolute;
    top: 10px;
    bottom: 10px;
    left: 10px;
    right: 10px;
    width: calc(100vw - 20px);
    height: calc(100vh - 100px);
    background-color: rgba(0, 64, 95, 1) !important;
    border: 1px solid $secondary !important;
    overflow: auto !important;

    h2 {
      margin: 30px auto !important;
      padding: 20px;
    }
  }

  .content {
    background-image: url("assets/bg/background.png");
    background-repeat: no-repeat;
    background-color: #080810 !important;
    background-size: cover;
    background-position: center center;
  }

  /* fade-transform */
  .router-transition-enter-active {
    transition: opacity .3s;
    transition-delay: .3s;
  }

  .router-transition-leave-active {
    transition: opacity .3s;
  }

  .router-transition-enter {
    opacity: 0;
  }

  .router-transition-leave-to {
    opacity: 0;
  }
</style>
