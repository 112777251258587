<template>
  <MglMap ref="map" width="100%" id="map" v-if="mapStyle" :accessToken="accessToken" :mapStyle="mapStyle"
          :dragRotate="false" :pitch="0" :min-zoom="8">
    <MglNavigationControl position="bottom-right"/>
    <map-marker></map-marker>
    <map-locations v-for="location in locations" :marker="location"></map-locations>
    <map-navigation></map-navigation>
    <map-route></map-route>
  </MglMap>
</template>

<script>
  import { mapActions, mapState } from 'vuex';
  import { ACTIONS as USER_ACTIONS } from '../../auth/account.constants';
  import { ACTIONS as ROUTE_ACTIONS } from './routes/routes.constants';
  import Mapbox from 'mapbox-gl';
  import { MglMap, MglNavigationControl } from 'vue-mapbox';
  import MapMarker from './marker';
  import MapNavigation from './navigation';
  import MapRoute from './routes/route';
  import MapLocations from './locations';

  export default {
    name: 'investigation-map',
    components: {
      MapLocations,
      MapRoute,
      MglNavigationControl,
      MapNavigation,
      MapMarker,
      MglMap,
    },
    data() {
      return {
        hiddenKey: 'hidden',
        toggleRoute: false,
        accessToken: process.env.VUE_APP_MAP_BOX_KEY,
        mapStyle: 'mapbox://styles/siebevh/ck9i7cpe70l8o1is3k6sxn8gd', // your map style
      };
    },
    watch: {
      $route() {
        if (this.$route.name === 'map') {
          this.getUser();
        }
      }
    },
    computed: {
      ...mapState({
        user: state => state.authentication.user,
        coordinates: state => state.route.marker,
      }),
      locations() {
        return this.user.request.filter(e => new Date(e.end) <= new Date()).map(e => ({
            coordinates: e.lastCoordinates,
        }))
      }
    },
    mounted() {
      this.getUser();
    },
    created() {
      this.mapbox = Mapbox;
      let visibilityChange;
      if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
        visibilityChange = 'visibilitychange';
      } else if (typeof document.msHidden !== 'undefined') {
        this.hidden = 'msHidden';
        visibilityChange = 'msvisibilitychange';
      } else if (typeof document.webkitHidden !== 'undefined') {
        this.hidden = 'webkitHidden';
        visibilityChange = 'webkitvisibilitychange';
      }
      window.addEventListener(visibilityChange, this.handleVisibilityChange, false);
    },
    destroyed() {
      window.removeEventListener(visibilityChange);
    },
    methods: {
      ...mapActions({
        getUser: USER_ACTIONS.GET_ME,
        updateRoute: ROUTE_ACTIONS.SET_LAST_COORDINATES,
      }),
      handleVisibilityChange() {
        if (document.visibilityState === 'visible') {
          this.$refs.map.map.flyTo(this.coordinates);
          this.updateRoute(this.user);
        }
      },
    }
  };
</script>

<style lang="scss">
  @import "@/styles/colors";

  #map {
    height: calc(100vh - 170px) !important;
  }

  .mapboxgl-map {
    width: 100% !important;
    height: calc(100vh - 170px) !important;
  }
</style>
