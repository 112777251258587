<template>
  <v-container class="dark" fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm12 md6 lg6 justify-center>
        <AnimatedLogo></AnimatedLogo>
        <div class="pa-4 form">
          <v-form dark v-model="valid" ref="form" v-if="isLogin">
            <v-text-field
              outlined
              color="secondary"
              label="Agent Nummer"
              v-model="username"
              required
            ></v-text-field>
            <v-text-field
              outlined
              color="secondary"
              label="Wachtwoord"
              v-model="password"
              required
              :append-icon="e1 ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="() => (e1 = !e1)"
              :type="e1 ? 'password' : 'text'"
            ></v-text-field>
            <v-layout class="right">
              <v-btn block outlined color="secondary" aria-label="Login" @click="localLogin">Inloggen</v-btn>
            </v-layout>
          </v-form>
          <v-form dark ref="form" v-else>
            <label class="mb-3">Gelieve een team naam te kiezen (bvb klasnaam + groep nr)</label>
            <v-text-field
              outlined
              color="secondary"
              label="team naam"
              v-model="name"
              required
            ></v-text-field>
            <v-layout class="right">
              <v-btn block outlined color="secondary" aria-label="Login" @click="updateName">Gebruik deze teamnaam</v-btn>
            </v-layout>
          </v-form>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex';
  import { ACTIONS, GETTERS } from './account.constants';
  import AnimatedLogo from './animatedLogo';

  export default {
    name: 'Login',
    components: { AnimatedLogo },
    data() {
      return {
        activePage: 'signIn',
        username: '',
        password: '',
        name: '',
        submitted: false,
        isLogin: true,
        e1: true,
        valid: false,
      };
    },
    computed: {
      ...mapState({
        user: state => state.authentication.user,
      }),
      ...mapGetters({
        loggedIn: GETTERS.LOGGED_IN,
      }),
    },
    mounted() {
      if (this.loggedIn) {
        this.logout();
        this.$router.push('/');
      }
    },
    methods: {
      ...mapActions({
        signInLocal: ACTIONS.LOGIN,
        setName: ACTIONS.SET_USER_NAME,
        logout: ACTIONS.LOGOUT
      }),
      async localLogin() {
        try {
          await this.signInLocal({
            username: this.username,
            password: this.password
          });
          if (this.user.investigationName === 'Djuimprov_hhc' && !this.user.startHour) {
              this.isLogin = false;
          } else {
            if (!this.user.startHour) {
              await this.$router.push('/intro')
            } else {
              await this.$router.push('/')
            }
          }
        } catch (e) {
          this.activePage = null;
        }
      },
      async updateName() {
        try {
          await this.setName({
            username: this.username,
            name: this.name
          });

          await this.$router.push('/intro')
        } catch (e) {
          this.activePage = null;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .v-text-field__details {
    margin-bottom: 8px;
    margin-top: 5px;
  }

  > > > .v-text-field__slot input {
    margin-top: 2px;
  }

  button {
    &.v-btn--disabled {
      color: #e0e0e0 !important;
    }
  }

  .form {
    visibility: hidden;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    animation-name: Enlarge;
    animation-duration: 4s;
    animation-delay: 3s;
  }


  @keyframes Enlarge {
    from {
      opacity: 0;
      visibility: hidden;
    }
    to {
      opacity: 1;
      visibility: visible;
    }
  }
</style>
