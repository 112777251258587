import { render, staticRenderFns } from "./draw-screen.vue?vue&type=template&id=3644589b&scoped=true&"
import script from "./draw-screen.vue?vue&type=script&lang=js&"
export * from "./draw-screen.vue?vue&type=script&lang=js&"
import style0 from "./draw-screen.vue?vue&type=style&index=0&id=3644589b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3644589b",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})
