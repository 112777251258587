import Vue from 'vue';
import Vuex from 'vuex';
import modules from '@/config/modules';

Vue.use(Vuex);

const store = new Vuex.Store({
	strict: true,
});

const entries = Object.entries(modules);

for (let i = 0; i < entries.length; i++) {
	const moduleDefinition = entries[i][1];
	store.registerModule(moduleDefinition.MODULE_NAME, moduleDefinition.store);
}

export default store;
