<template>
  <v-layout>
    <v-container fluid v-if="game">
      <h1 class="text-md-center barcode-title">HINTS</h1>
      <v-container fluid v-for="location in hints" :key="location.name">
        <h2 class="text-md-center">{{location.name}}</h2>
        <v-expansion-panels
          flat
          hover
          color="secondary"
        >
          <v-expansion-panel
            v-for="(item,i) in location.hints"
            :key="i"
          >
            <v-expansion-panel-header  @click="onFocus(location.name, i)">{{i === location.hints.length - 1 ? 'OPLOSSING': 'HINT ' + (i + 1) }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <div v-if="item.img">
                <div class="mt-2 text--white white--text">
                  {{item.text}}
                </div>
                <v-img v-if="item.img" max-height="30vh" contain :src="require(`@/assets/hints/${item.img}`)" class="mt-2"></v-img>
              </div>
              <div v-else class="mt-2 text--white white--text">
                {{item}}
              </div>

            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-container>
    </v-container>
  </v-layout>

</template>

<script>
  import { mapState } from 'vuex';
  import api from '../../services/backendApi';

  export default {
    name: 'hints',
    data() {
      return {
        hintsOpen: []
      };
    },
    computed: {
      ...mapState({
        loading: state => state.game.loading,
        game: state => state.game.game,
      }),
      hints() {
        return this.game.hints;
      }
    },
    watch: {
      hintsOpen(newValue) {
        console.log(newValue);
      }
    },
    methods:{
      onFocus(location, number) {
        api.post(`${process.env.VUE_APP_API_URL}/users/hints`, {location: location + ' ' + number}).then(() => {})
      }
    }
  };
</script>

<style lang="scss">
  @import "@/styles/colors.scss";

  .theme--light.v-expansion-panels .v-expansion-panel {
    background-color: rgba(0, 53, 73, 0.89)!important;
    color: $secondary!important;
    border: solid 1px #0081c0;
  }

  .v-expansion-panel-header {
    background-color: rgba(0, 53, 73, 0.95)!important;
  }
</style>
