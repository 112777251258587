<template>
  <v-tour name="intro" :steps="steps">
    <template slot-scope="tour">
      <transition name="fade">
        <v-step
          v-for="(step, index) of tour.steps"
          :key="index"
          v-if="tour.currentStep === index"
          :step="step"
          :previous-step="tour.previousStep"
          :next-step="tour.nextStep"
          :stop="tour.stop"
          :skip="tour.skip"
          :is-first="tour.isFirst"
          :is-last="tour.isLast"
          :labels="tour.labels"
        >
          <template v-if="tour.isFirst">
            <div slot="actions">
              <v-btn color="secondary" outlined @click="tour.nextStep" class="btn btn-primary">{{$t("tour.next")}}</v-btn>
            </div>
          </template>
          <template v-if="tour.currentStep === 3">
            <div slot="content">
              {{$t('tour.map-line1')}}
              <br> <img :src="require('@/assets/marker.svg')" width="25px"/>: {{$t('tour.map-line2')}}
              <br> <img :src="require('@/assets/location/investigation.svg')" width="25px"/>: {{$t('tour.map-line3')}}!
            </div>
          </template>
          <template v-if="!tour.isFirst && !tour.isLast">
            <div slot="actions">
              <v-btn color="secondary" outlined @click="tour.previousStep" class="btn btn-primary mr-2">{{$t("tour.previous")}}</v-btn>
              <v-btn color="secondary" outlined @click="tour.nextStep" class="btn btn-primary">{{$t("tour.next")}}</v-btn>
            </div>
          </template>
          <template v-if="tour.isLast">
            <div slot="actions">
              <v-btn color="secondary" outlined @click="tour.previousStep" class="btn btn-primary mr-2">{{$t("tour.previous")}}</v-btn>
              <v-btn color="secondary" outlined @click="tour.finish();startGame(game)" class="ml-3">{{$t("tour.start")}}</v-btn>
            </div>
          </template>
        </v-step>
      </transition>
    </template>
  </v-tour>
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import { ACTIONS } from '../../views/map/routes/routes.constants';

  export default {
    name: 'tour',
    data() {
      return {
        showButtons: false,
        myCallbacks: {
          onPreviousStep: this.myCustomPreviousStepCallback,
          onNextStep: this.myCustomNextStepCallback
        },
        steps: [
          {
            target: '#menu',  // We're using document.querySelector() under the hood
            header: {
              title: 'Menu',
            },
            params: {
              placement: 'left'
            },
            content: this.$t('tour.menu')
          },
          {
            target: '#hints',  // We're using document.querySelector() under the hood
            header: {
              title: 'Hints',
            },
            content: this.$t('tour.hints')
          },
          {
            target: '#timeLeft',  // We're using document.querySelector() under the hood
            header: {
              title: this.$t('tour.time-left-title'),
            },
            content: this.$t('tour.time-left')
          },
          {
            target: '#map',
            header: {
              title: this.$t('tour.map-title'),
            },
          },
          {
            target: '.navigation',  // We're using document.querySelector() under the hood
            header: {
              title: this.$t('tour.send-team-title'),
            },
            content: this.$t('tour.send-team')
          },
        ]
      };
    },
    computed: {
        ...mapState({
          game: state => state.game.game,
        })
    },
    methods: {
      ...mapActions({
        startGame: ACTIONS.START_GAME,
      }),
    }
  };
</script>

<style lang="scss">
  @import "@/styles/colors.scss";

  .v-step {
    background-color: #004363 !important;
    color: white !important;
    border-radius: 10px !important;
    max-width: 500px!important;
  }

  .v-step .v-step__arrow {
    border-color: white !important;       /* set to desired popper arrow color */
    &--dark {
      border-color: white !important;   /* set to desired popper arrow color */
    }
  }
  .v-step[x-placement^='top'] .v-step__arrow {
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
  }

  .v-step[x-placement^='bottom'] .v-step__arrow {
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-top-color: transparent !important;
  }

  .v-step[x-placement^='right'] .v-step__arrow {
    border-left-color: transparent !important;
    border-top-color: transparent !important;
    border-bottom-color: transparent !important;
  }

  .v-step[x-placement^='left'] .v-step__arrow {
    border-top-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
  }

  .position {
    background: url("../../assets/marker.svg");
    width: 50px;
    height: 50px;
  }

  .v-step__header {
    background-color: #003549 !important;
    border-radius: 10px 10px 0 0 !important;
  }

</style>
