<template>
  <simple-svg :src="require('@/assets/logo.svg')"></simple-svg>
</template>

<script>
  import {SimpleSVG} from 'vue-simple-svg'

  export default {
    name: 'AnimatedLogo',
    components: {
      'simple-svg': SimpleSVG
    },
  };
</script>

<style lang="scss">
  .circle {
    fill-opacity: 0;
    stroke-dasharray: 400;
    stroke-dashoffset: 200;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    animation-iteration: 1;
    animation-name: DrawLine;
    animation-duration: 4s;
    animation-delay: 0s;
    padding-top: 200px;
  }

  .inner {
    fill-opacity: 0;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    animation-iteration: 1;
    animation-name: DrawLine;
    animation-duration: 3s;
    animation-delay: 0s;
    padding-top: 200px;
  }

  .logo {
    visibility: hidden;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    animation-iteration: 1;
    animation-name: SlideUp;
    animation-duration: 3s;
    padding-top: 350px;

    margin: auto;
    display: block;
  }

  @keyframes DrawLine {
    from {
      fill-opacity: 0;
    }
    to {
      fill-opacity: 1;
    }
  }

  @keyframes SlideUp {
    from {
      visibility: hidden;
      padding-top: 20vh;
      height: 60vh;
    }
    1% {
      visibility: visible;
    }
    to {
      padding-top: 0;
      height: 30vh;
      visibility: visible;
    }
  }
</style>
