<template>
  <v-navigation-drawer
    id="menu"
    color="transparent"
    width="300px"
    class="pl-4"
    persistent
    enable-resize-watcher
    fixed
    app
  >
    <v-list class="pa-5 justify-center">
      <v-list-item class="white--text justify-center">
          <v-img :src="logo.src" :max-width="width"></v-img>
      </v-list-item>
    </v-list>
    <v-list-item class="list-item ma-3" v-for="item of gateway" :disabled="!startDate" :event="startDate ? 'click' : ''" :to="item.link" :key="item.text">
      <v-list-item-action class="pl-3">
        <v-icon color="secondary darken-1">{{item.icon}}</v-icon>
      </v-list-item-action>
      <v-list-item-title class="secondary--text">{{ item.text }}</v-list-item-title>
    </v-list-item>
  </v-navigation-drawer>
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import { ACTIONS } from '../../auth/account.constants';

  export default {
    name: 'AppSidebar',
    computed: {
      ...mapState({
        startDate: state => state.route.startHour,
      })
    },
    data() {
      return {
        width: '140px',
        route: '',
        logo: { src: require('@/assets/logo.svg') },
        gateway: [
          {
            icon: 'mdi-earth',
            text: this.$t('menu.map'),
            link: '/map'
          },
          {
            icon: 'mdi-bank',
            text: this.$t('menu.bank'),
            link: '/atm'
          },
          {
            icon: 'mdi-safe-square-outline',
            text: this.$t('menu.safe'),
            link: '/safe'
          },
          {
            icon: 'mdi-passport',
            text: this.$t('menu.passport'),
            link: '/passport'
          },
          {
            icon: 'mdi-car',
            text: this.$t('menu.licence-plate'),
            link: '/licence-plate'
          },
          {
            icon: 'mdi-package-variant-closed',
            text: this.$t('menu.packets'),
            link: '/package'
          },
          {
            icon: 'mdi-office-building',
            text: this.$t('menu.companies'),
            link: '/companies'
          },
          {
            icon: 'mdi-account-star-outline',
            text: this.$t('menu.clients'),
            link: '/clients'
          },
          {
            icon: 'mdi-phone',
            text: this.$t('menu.phone'),
            link: '/phone'
          },
          {
            icon: 'mdi-logout',
            text: this.$t('menu.logout'),
            link: '/login'
          },
        ],
      };
    },
  };
</script>

<style lang="scss" scoped>
  @import "@/styles/colors.scss";

  .active-route {
    background-color: $navigation-grey;
  }

  .list-item {
    background-color: rgba(0, 129, 192, 0.24);
    border: solid 1px $secondary;
  }

  .list-item:hover {
    background-color: rgba(0, 170, 255, 0.24);
    border: solid 1px $secondary;
  }

  .secondary--text {
    color: $secondary !important;
  }


</style>
