<template>
  <MglGeojsonLayer
    sourceId="route"
    :source="route"
    layerId="route-layer"
    :layer="geoJsonlayer"
  />
</template>

<script>
  import { mapState } from 'vuex';
  import { MglGeojsonLayer } from 'vue-mapbox';

  export default {
    name: 'mapRoute',
    inject: ['mapbox', 'map', 'actions'],
    components: {
      MglGeojsonLayer,
    },
    data() {
      return {
        geoJsonlayer: {
          'source': 'route',
          'type': 'line',
          "layout": {
            "line-join": "round",
            "line-cap": "round"
          },
          'paint': {
            'line-color': '#00ffff',
            'line-width': {
              base: 5,
              stops: [[10, 3], [13, 5], [16, 14]]
            },

          }
        },
      };
    },
    computed: {
      ...mapState({
        route: state => state.route.route,
      }),
    },
  };
</script>
